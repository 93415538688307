import {AdvertisingCampaign} from '../../api';

import {ADVERTISING_CAMPAIGN_CREATE_FAILURE, ADVERTISING_CAMPAIGN_CREATE_SUCCESS} from '../../constants';
import {normalize} from "normalizr";
import advertisingCampaignSchema from "../../schemas/advertising_campaign";


const SuccessAction = (payload) => ({
  type: ADVERTISING_CAMPAIGN_CREATE_SUCCESS,
  payload: normalize([payload], [advertisingCampaignSchema])
});

const FailureAction = (payload) => ({
  type: ADVERTISING_CAMPAIGN_CREATE_FAILURE,
  payload
});

export default (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    AdvertisingCampaign.AdvertisingCampaignCreate(data)
      .then(response => {
        dispatch(SuccessAction(response));
        resolve(response);
      })
      .catch(error => {
        dispatch(FailureAction(error));
        reject(error);
      })
  })
};

import React from "react";
import {Redirect, Route, Switch} from 'react-router-dom'
import {connect} from "react-redux";
import {getAccount, getSessionToken} from "./selectors";
import {Preloader} from "./components";
import {Account, Session} from "./actions"
import * as account from "./layouts";
import {Header} from "./layouts";


class Auth extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      pending: true,
    };
  }

  componentWillUpdate(nextProps) {
    if (nextProps.token && nextProps.token !== this.props.token) {
      this.fetchAccount()
    }
  }

  componentDidMount() {
    this.fetchAccount()
      .then(() => {
        this.setState({pending: false})
      });
  }

  fetchAccount = () => {
    return this.props.dispatch(Account.Fetch())
      .then((a) => {
        this.props.dispatch(Account.AccountCurrentSet(a));
      })
      .catch(() => {
        this.props.dispatch(Session.Remove());
      });
  };

  render() {

    if (!this.props.token) return <Redirect to="/signin"/>;
    if (this.state.pending) {
      return (
        <div className="d-flex justify-content-center align-items-center vh-100">
          <Preloader/>
        </div>
      );
    }

    return (
      <div>

        <Header/>

        <div className="content-wrapper">
          <Switch>

            <Route exact path={this.props.match.url} component={account.PageDashboard}/>

            {/*******************************************/}
            {/***************** account *****************/}
            {/*******************************************/}

            <Route exact path="/account" component={account.PageAccountSettings}/>
            <Route exact path="/account/billing" component={account.PageAccountTransactionInfo}/>

            {/*******************************************/}
            {/******************* /campaign *************/}
            {/*******************************************/}

            <Route exact path="/campaign" component={account.AdvertisingCampaignPageList}/>
            <Route exact path="/campaign/new" component={account.AdvertisingCampaignPageCreate}/>
            <Route exact path="/campaign/:campaign" component={account.AdvertisingCampaignPageInfo}/>

            {/*******************************************/}
            {/**************+*** media **************+***/}
            {/*******************************************/}

            <Route exact path="/media" component={account.MediaPageList}/>

            {/*******************************************/}
            {/***************** Redirect ****************/}
            {/*******************************************/}

            <Redirect from='*' to='/404'/>

          </Switch>
        </div>

      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    account: getAccount(state),
    token: getSessionToken(state)
  }
};

export default connect(mapStateToProps)(Auth);

import React from "react";
import {connect} from "react-redux";
import {NavLink, withRouter} from "react-router-dom";
import {AdvertisingCampaignFormUpdate, Button} from "./../containers";
import {I18n} from "../utils/i18n";
import {AdvertisingCampaign} from "../actions";
import {getAdvertisementCampaignByID} from "../selectors";
import {AdFormTagsStatisticsInfo, Preloader} from "./../components";
import * as api from "../api";
import {formatDate} from "../utils/helpers";

class AdvertisingCampaignPageInfo extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      waiting: true,
      pending: false,
      data: {
        tags_statistics_list: [],
        ad: {},
      },
    };
  }

  componentDidMount() {
    this.props.dispatch(AdvertisingCampaign.Fetch(this.props.match.params.campaign))
      .then(() => {
        return api.AdvertisingCampaign.AdvertisingCampaignTagsStatisticsList(this.props.match.params.campaign)
          .then((res) => {
            this.setState({waiting: false, data: {...this.state.data, tags_statistics_list: res}})
          });
      })
      .catch(() => {
        this.props.history.push("/campaign")
        this.setState({waiting: false})
      });
  }

  handleHideAdvertisingCampaignUpdateForm = () => this.props.history.push("/campaign");

  handleSubmitUpdateAdvertisingCampaign = () => {
    const {campaign} = this.props;

    this.setState({pending: true});

    let data = {...this.state.data.ad};

    let spec = {};
    spec.name = data.name;
    spec.file_id = data.file.id;
    spec.tags = data.tags;
    spec.use_time = data.use_time;
    spec.start = formatDate(data.start);
    spec.end = formatDate(data.end);

    spec.start_time = data.start_time.toLocaleTimeString('ru-RU', {
      hour12: false,
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    });
    spec.end_time = data.end_time.toLocaleTimeString('ru-RU', {
      hour12: false,
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    });

    return this.props.dispatch(AdvertisingCampaign.Update(campaign.id, spec))
      .then(this.handleSuccess, this.handleError)
  };


  handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    let value = target.value;

    this.setState({data: {...this.state.data, [name]: value}})
  }

  handleSuccess = () => {
    this.setState({pending: false});
  };

  handleError = (errors) => {
    let e = this.state.error;
    if (errors.status === "Bad Request") e.form = errors.message;
    this.setState({error: e, pending: false});
  };

  handleDisabled = () => {
    return (
      this.state.pending
      || !this.state.data.ad.name
      || !this.state.data.ad.file
      || !this.state.data.ad.tags.length
      || (!this.state.data.ad.start || !this.state.data.ad.end || !(!!this.state.data.ad.start && !!this.state.data.ad.end && this.state.data.ad.start.getTime() <= this.state.data.ad.end.getTime()))
    )
  };

  handleExportToExcel = () => {
    if (this.props.campaign.status.status !== "done") return;
    api.AdvertisingCampaign.AdvertisingCampaignTagsStatisticsExcelExport(this.props.campaign.meta.id, this.props.campaign.meta.name)
  }

  render() {

    if (this.state.waiting) return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <Preloader/>
      </div>
    );

    const {campaign} = this.props;

    return (
      <section className="container content">

        <div className="subnav-panel">
          <div>
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><NavLink to={"/"}>{I18n.t('key_Home')}</NavLink></li>
              <li className="breadcrumb-item">
                <NavLink to={"/campaign"}>{I18n.t('key_AdvertisingCampaign')}</NavLink>
              </li>
              <li className="breadcrumb-item active">{campaign.meta.name}</li>
            </ol>
          </div>
        </div>


        <div className="card">
          <div className="card-body">

            <div className="d-flex flex-row mb-4">
              <div>
                <h2>{I18n.t('key_AdvertisingCampaignPageInto_Title')}</h2>
              </div>
              <div className="px-3 py-1">
                <h3>
                  {(campaign.status.status === "created") &&
                  <span className="badge badge-primary">{I18n.t('key_StatusAdCreated')}</span>}
                  {(campaign.status.status === "accepted") &&
                  <span className="badge badge-warning">{I18n.t('key_StatusAdAccepted')}</span>}
                  {(campaign.status.status === "rejected") &&
                  <span className="badge badge-danger">{I18n.t('key_StatusAdRejected')}</span>}
                  {(campaign.status.status === "done") &&
                  <span className="badge badge-success">{I18n.t('key_StatusAdDone')}</span>}
                </h3>
              </div>
            </div>

            <div className="row">
              <div className="col-12 mb-5">
                <AdvertisingCampaignFormUpdate name="ad"
                                               data={campaign}
                                               disabled={campaign.status.status !== "created"}
                                               onChange={this.handleChange}/>
              </div>

              <div className="col-6 mb-5">
                <div className="d-flex  mb-4">
                  <div className="pr-2">
                    <h2>{I18n.t('key_AdvertisingCampaignPageInto_TagsStatistics')}</h2>
                  </div>
                  <div className="align-self-center pb-2">
                    <button
                      disabled={campaign.status.status !== "done"}
                      className="btn btn-sm btn-success text-uppercase"
                      onClick={this.handleExportToExcel}>
                      <i className="fa fa-file-excel"/>&nbsp;{I18n.t('key_ExportToExcel')}
                    </button>
                  </div>
                </div>
                <div>
                  <AdFormTagsStatisticsInfo list={this.state.data.tags_statistics_list}/>
                </div>
              </div>

            </div>


            <div className="pt-4 d-flex justify-content-between">
              <div className="col-6 pl-0 text-left">
                <button className="btn btn-inverse"
                        onClick={this.handleHideAdvertisingCampaignUpdateForm}
                        type="button">
                  {I18n.t('key_Close')}
                </button>
              </div>

              <div className="col-6 pr-0 text-right">
                {
                  (campaign.status.status === "created") && (
                    <Button type='submit'
                            className='btn btn-primary text-capitalize'
                            disabled={this.handleDisabled()}
                            submit={this.handleSubmitUpdateAdvertisingCampaign}
                            main={I18n.t('key_Apply')}
                    />
                  )
                }
              </div>
            </div>

          </div>
        </div>

      </section>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    campaign: getAdvertisementCampaignByID(state, props.match.params.campaign),
  }
};

export default withRouter(connect(mapStateToProps)(AdvertisingCampaignPageInfo));

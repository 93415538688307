export default {
  "key_ComingSoon": "Скоро будет..",

  "key_Rub": "руб",

  "key_Help": "Помощь",
  "key_TermsAndConditions": "Условия использования",
  "key_PrivacyPolicy": "Политика конфиденциальности",

  // -- Preloader
  "key_Preloader_PleaseWait": "Пожалуйста, подождите немного, пока мы готовим контент!",

  // -- Buttons
  "key_Update": "Обновить",
  "key_Remove": "Удалить",
  "key_Create": "Создать",
  "key_Cancel": "Отменить",
  "key_Add": "Добавить",
  "key_Apply": "Применить",
  "key_Close": "Закрыть",
  "key_Select": "Выбрать",
  "key_Change": "Изменить",
  "key_Confirm": "Подтвердить",
  "key_Back": "Назад",
  "key_Connect": "Подключить",
  "key_Disconnect": "Отключить",
  "key_SignIn": "Войти в систему",
  "key_SignUp": "Создать аккаунт",
  "key_Send": "Отправить",
  "key_Login": "Авторизоваться",
  "key_LogOut": "Выйти",
  "key_Recovery": "Восстановить",
  "key_Upload": "Загрузить",
  "key_Clear": "Очистить",
  "key_Search": "Поиск",
  "key_Export": "Экспорт",
  "key_ExportToExcel": "Экспорт в Excel",

  "key_StatusAdCreated": "Новая",
  "key_StatusAdAccepted": "Готова",
  "key_StatusAdRejected": "Откказ",
  "key_StatusAdDone": "Выполнена",

  "key_StatusMediaError": "Ошибка",
  "key_StatusMediaReady": "Готов",
  "key_StatusMediaUploading": "Загрузка",
  "key_StatusMediaPreparing": "Подготовка",
  "key_StatusMediaDeleting": "Удаление",
  "key_StatusMediaUnknown": "Неопределен",

  "key_StatusMediaCreated": "Новый",
  "key_StatusMediaAccepted": "Принят",
  "key_StatusMediaRejected": "Отклонен",

  "key_NewTransaction": "Пополнить счет",
  "key_CustomerBalance": "Текущий баланс",

  "key_Home": "Главная",
  "key_Media": "Медиа",
  "key_Profile": "Профайл",
  "key_AdvertisingCampaign": "Рекламная кампания",
  "key_Dashboard": "Информационная панель",
  "key_Transactions": "Транзакции",

  // Errors
  "key_errorMsg_FORBIDDEN": "Доступ запрещен",
  "key_errorMsg_INVALID_INCOMING_JSON": "Неверные входящие данные",
  "key_errorMsg_BadRequest": "Плохой запрос",
  "key_errorMsg_BadEmailParameter": "Неверный параметр email",
  "key_errorMsg_BadLastNameParameter": "Неверный параметр фамилия",
  "key_errorMsg_BadFirstNameParameter": "Неверный параметр имя",
  "key_errorMsg_BadPhoneParameter": "Неверный параметр телефон",
  "key_errorMsg_BadUsernameParameter": "Неверный параметр username",
  "key_errorMsg_BadNameParameter": "Неверный параметр имени",
  "key_errorMsg_BadPasswordParameter": "Неверный параметр пароля",
  "key_errorMsg_BadInviteCodeParameter": "Пригласительный код невалиден",
  "key_errorMsg_USERNAME_CANNOT_BE_BLANK": "Имя пользователя не может быть пустым",
  "key_errorMsg_USERNAME_OR_EMAIL_INVALID": "Invalid username or email",
  "key_errorMsg_USERNAME_EXISTS": "Неверное имя пользователя или адрес электронной почты",
  "key_errorMsg_USERNAME_FORMAT_INVALID": "Имя пользователя может содержать только буквенно-цифровые символы или подчеркивание и не может начинаться или заканчиваться подчеркиванием",
  "key_errorMsg_USERNAME_TO_SHORT": "Имя пользователя слишком короткое (минимум 4 символа)",
  "key_errorMsg_USERNAME_TO_LONG": "Имя пользователя слишком длинное (максимум 64 символа)",
  "key_errorMsg_EMAIL_EXISTS": "Адрес электронной почты уже существует",
  "key_errorMsg_EMAIL_DOES_NOT_EXISTS": "Электронная почта не существует",
  "key_errorMsg_EMAIL_CANNOT_BE_BLANK": "Поле электронной почты не может быть пустым",
  "key_errorMsg_EMAIL_FORMAT_INVALID": "Неверный формат электронной почты",
  "key_errorMsg_PASSWORD_CANNOT_BE_BLANK": "Пароль не может быть пустым",
  "key_errorMsg_PASSWORD_FORMAT_INVALID": "Неверный формат пароля",
  "key_errorMsg_PASSWORDS_ARE_EQUAL": "Новый и текущий пароль совпадают",
  "key_errorMsg_PASSWORDS_NOT_EQUAL": "Пароли не совпадают",
  "key_errorMsg_PASSWORD_TO_SHORT": "Пароль слишком короткий (минимум 8 символов)",
  "key_errorMsg_PASSWORD_NOT_VALID": "Неверный пароль",
  "key_errorMsg_NAME_CANNOT_BE_BLANK": "Имя не может быть пустым",
  "key_errorMsg_NAME_FORMAT_INVALID": "Имя может содержать только буквенно-цифровые символы или одиночные дефисы или подчеркивания и не может начинаться или заканчиваться дефисом или подчеркиванием",
  "key_errorMsg_NAME_TO_SHORT": "Имя слишком короткое (минимум 4 символа)",
  "key_errorMsg_NAME_TO_LONG": "Имя слишком длинное (максимум 64 символа)",
  "key_errorMsg_INVALID_REDIRECT_URI": "Неверный редирект URI",
  "key_errorMsg_INVALID_FIELD_VALUE": "Неверное значение поля",
  "key_errorMsg_FIELD_CANNOT_BE_BLANK": "Поле не может быть пустым",
  "key_errorMsg_INTERNAL_SERVER_ERROR": "Внутренняя ошибка сервера",
  "key_errorMsg_UNDEFINED": "Неизвестная ошибка",
  "key_errorMsg_LOGIN_FAILED": "Неправильный логин",
  "key_errorMsg_PASSWORD_FAILED": "Неверный пароль",
  "key_errorMsg_SIGNIN_FAILED": "Неверный логин или пароль",
  "key_errorMsg_SIGNUP_FAILED": "Неверный логин или адрес электронной почты или пароль",
  "key_errorMsg_UNAUTHORIZED": "Вы должны быть зарегистрированы",
  "key_errorMsg_BAD_REQUEST": "Ваш браузер отправил запрос, который этот сервер не может понять",
  "key_errorMsg_NOT_FOUND": "Не найдено",
  "key_errorMsg_NOT_IMPLEMENTED": "Не реализована",
  "key_errorMsg_NOT_ACCEPTABLE": "Неверный параметр",
  "key_errorMsg_BAD_GATEWAY": "Плохой шлюз",
  "key_errorMsg_FIELD_TOTAL_LESS_FIELD_DAILY": "Общее количество не может быть меньше повседневного",
  "key_errorMsg_INCORRECT_DATE_OR_TIME": "Неверная дата или время",

  // -- AccountPartialSignIn
  "key_AccountPartialSignIn_Description": "Платформа управления клиентами ADS",

  // -- AccountFormSignIn
  "key_AccountFormSignIn_LabelLogin": "Почтовый адрес",
  "key_AccountFormSignIn_PlaceholderLogin": "Почтовый адрес",
  "key_AccountFormSignIn_LabelPassword": "Пароль",
  "key_AccountFormSignIn_PlaceholderPassword": "Пароль",
  "key_AccountFormSignIn_ForgotPassword": "Забыли пароль?",
  "key_AccountFormSignIn_CreateAccount": "Создать аккаунт",

  // -- AccountFormSignUp
  "key_AccountFormSignUp_LabelUsername": "Логин",
  "key_AccountFormSignUp_PlaceholderUsername": "Логин",
  "key_AccountFormSignUp_LabelEmail": "Почтовый адрес",
  "key_AccountFormSignUp_PlaceholderEmail": "Почтовый адрес",
  "key_AccountFormSignUp_LabelPassword": "Пароль",
  "key_AccountFormSignUp_PlaceholderPassword": "Пароль",
  "key_AccountFormSignUp_ButtonCreateAccount": "Создать аккаунт",
  "key_AccountFormSignUp_SigningUpOrSigningUp": "Регистрация означает, что вы прочитали и согласны с Условиями обслуживания и нашей Политикой конфиденциальности.",

  // -- AccountPartialSignUp
  "key_AccountFormSignUp_Description": "ADS customer management platform",
  "key_AccountFormSignUp_IHaveAccount": "Я уже имею аккаунт",

  // -- AccountPartialSignUp
  "key_AccountPartialSignUp_Description": "Платформа управления клиентами ADS",

  // -- Header
  "key_Header_TabItemDashboardMenu": "Главная",
  "key_Header_TabItemMediaMenu": "Медиа",
  "key_Header_TabItemAdvertisingCampaignMenu": "Рекламная кампания",
  "key_Header_Dropdown_Settings": "Настройки",
  "key_Header_Balance": "Баланс %{value}",


  // -- AccountHeader
  "key_AccountHeader_ManageAccount": " Управление аккаунтом",
  "key_AccountHeader_TabProfile": "Аккаунт",

  // -- AccountPartialGeneral
  "key_AccountPartialGeneral_Profile": "Профайл",
  "key_AccountPartialGeneral_YorEmailIsIdentity": "Ваш электронный адрес является вашей личностью и используется для входа.",
  "key_AccountPartialGeneral_Password": "Пароль",
  "key_AccountPartialGeneral_ChangingPassword": "Изменение пароля также приведет к сбросу ключа API.",
  "key_AccountPartialGeneral_Organization": "Организация",
  "key_AccountPartialGeneral_OrganizationDesc": "Реквизиты организации",
  "key_AccountPartialGeneral_Language": "Язык",
  "key_AccountPartialGeneral_ChangeLanguage": "Изменение языка",

  // -- AccountFormProfile
  "key_AccountFormProfile_LabelEmailAddress": "Адрес электронной почты",
  "key_AccountFormProfile_PlaceholderEmailAddress": "Адрес электронной почты",
  "key_AccountFormProfile_LabelName": "Имя",
  "key_AccountFormProfile_PlaceholderName": "Имя",
  "key_AccountFormProfile_LabelBio": "О вас",
  "key_AccountFormProfile_PlaceholderBio": "О вас",

  // -- AccountFormPasswordChange
  "key_AccountFormPasswordChange_LabelCurrentPassword": "Текущий пароль",
  "key_AccountFormPasswordChange_PlaceholderCurrentPassword": "Текущий пароль",
  "key_AccountFormPasswordChange_LabelEnterNewPassword": "Введите новый пароль",
  "key_AccountFormPasswordChange_PlaceholderEnterNewPassword": "Введите новый пароль",
  "key_AccountFormPasswordChange_LabelConfirmPassword": "Повторный ввод пароля",
  "key_AccountFormPasswordChange_PlaceholderConfirmPassword": "Повторный ввод пароля",

  // -- AccountPartialRecovery_RecoveryPassword
  "AccountPartialRecovery_RecoveryPassword": "Восстановление пароля",

  // -- AccountFormPasswordRecovery
  "key_AccountFormPasswordRecovery_LabelEmail": "Электронная почта",
  "key_AccountFormPasswordRecovery_PlaceholderEmail": "Электронная почта",
  "key_AccountFormPasswordRecovery_SendRecoveryEmailButton": "Отправить письмо для восстановления",
  "key_AccountFormPasswordRecovery_LabelConfirmPassword": "Подтвердите новый пароль",
  "key_AccountFormPasswordRecovery_PlaceholderConfirmPassword": "Подтвердите новый пароль",
  "key_AccountFormPasswordRecovery_LabelEnterNewPassword": "Введите новый пароль",
  "key_AccountFormPasswordRecovery_PlaceholderEnterNewPassword": "Введите новый пароль",

  // -- PageRecovery
  "key_PageRecovery_PasswordRecovery": "Восстановление пароля",
  "key_PageRecovery_MessageSent": "На вашу почту было отправлено сообщение",
  "key_PageRecovery_ReturnToSignIn": "вернуться на страницу входа",
  "key_PageRecovery_BackToLogin": "Вернуться на страницу авторизации",

  // -- PageNotFound
  "key_PageNotFound_404PageNotFound": "404 страница не найдена",
  "key_PageNotFound_WeAreSorry": "К сожалению, страница, которую вы ищете, не существует.",
  "key_PageNotFound_GoToHOME": "ВЕРНУТЬСЯ ДОМОЙ",

  // -- MediaFileCardList
  "key_MediaFileCardList_TableHeaderStatus": "Состояние",
  "key_MediaFileCardList_TableHeaderState": "Статус",
  "key_MediaFileCardList_TableHeaderName": "Название",
  "key_MediaFileCardList_TableHeaderSize": "Размер",
  "key_MediaFileCardList_TableHeaderUpdated": "Создан",

  // -- AdvertisingCampaignPageList
  "key_AdvertisingCampaignPageList_Title": "Рекламные компании",
  "key_AdvertisingCampaignPageList_CreateNew": "Создание",

  // -- AdvertisingCampaignPageCreate
  "key_AdvertisingCampaignPageCreate_Title": "Рекламную компанию",
  "key_AdvertisingCampaignPageCreate_CreateNew": "Создание",

  // -- key_AdvertisingCampaignPageInto_Title
  "key_AdvertisingCampaignPageInto_Title": "Рекламная компания",
  "key_AdvertisingCampaignPageInto_TagsStatistics": "Статистика по тегам",

  // -- AdvertisingCampaignCardList
  "key_AdvertisingCampaignCardList_TableHeaderStatus": "Статус",
  "key_AdvertisingCampaignCardList_TableHeaderName": "Название",
  "key_AdvertisingCampaignCardList_TableHeaderInterval": "Интервал",
  "key_AdvertisingCampaignCardList_TableHeaderTags": "Теги",
  "key_AdvertisingCampaignCardList_TableHeaderUpdated": "Обновлен",
  "key_AdvertisingCampaignCardList_TableHeaderCreated": "Создан",
  "key_AdvertisingCampaignCardList_Tags": "Теги",

  // -- AdvertisingCampaignFormCreate
  "key_AdvertisingCampaignFormCreate_LabelName": "Название",
  "key_AdvertisingCampaignFormCreate_PlaceholderName": "Название",
  "key_AdvertisingCampaignFormCreate_TagsTitle": "Теги",
  "key_AdvertisingCampaignFormCreate_ThereAreNoTagsAvailable": "Нет доступных тегов. Обратитесь к Администратору.",
  "key_DeviceScheduleFormCreate_MediaFileTitle": "Медиа файл",
  "key_AdvertisingCampaignFormCreate_GeneralTitle": "Информация",
  "key_AdvertisingCampaignFormCreate_PlaybackSettingsTitle": "Настройка даты и времени воспроизведения контента",
  "key_AdvertisingCampaignFormCreate_LabelDateOrDates": "Дата/Даты",
  "key_AdvertisingCampaignFormCreate_LabelDateRange": "Период",
  "key_AdvertisingCampaignFormCreate_PlaceholderSelectDay": "Выберите день",
  "key_AdvertisingCampaignFormCreate_PlaceholderSelectStartTime": "Выберите время старта показа",
  "key_AdvertisingCampaignFormCreate_PlaceholderSelectEndTime": "Выберите время окончания показа",
  "key_AdvertisingCampaignFormCreate_PlaceholderSelectStartDay": "Выберите день старта показа",
  "key_AdvertisingCampaignFormCreate_PlaceholderSelectEndDay": "Выберите день окончания показа",
  "key_AdvertisingCampaignFormCreate_PlaceholderLimitTitle": "Настройка количества воспроизведений",
  "key_AdvertisingCampaignFormCreate_LabelEveryDay": "Каждый день",
  "key_AdvertisingCampaignFormCreate_LabelWorkDays": "Рабочие дни",
  "key_AdvertisingCampaignFormCreate_LabelWeekend": "Выходные дни",
  "key_AdvertisingCampaignFormCreate_LabelCustom": "Выбрать дни",
  "key_AdvertisingCampaignFormCreate_LabelMonday": "пн",
  "key_AdvertisingCampaignFormCreate_LabelTuesday": "вт",
  "key_AdvertisingCampaignFormCreate_LabelWednesday": "ср",
  "key_AdvertisingCampaignFormCreate_LabelThursday": "чт",
  "key_AdvertisingCampaignFormCreate_LabelFriday": "пт",
  "key_AdvertisingCampaignFormCreate_LabelSaturday": "сб",
  "key_AdvertisingCampaignFormCreate_LabelSunday": "вс",
  "key_AdvertisingCampaignFormCreate_LabelTotalLimit": "Всего показов",
  "key_AdvertisingCampaignFormCreate_PlaceholderTotalLimit": "Всего показов",
  "key_AdvertisingCampaignFormCreate_LabelDailyLimit": "Показов в день",
  "key_AdvertisingCampaignFormCreate_PlaceholderDailyLimit": "Показов в день",

  // -- AdvertisingCampaignFormUpdate
  "key_AdvertisingCampaignFormUpdate_LabelName": "Название",
  "key_AdvertisingCampaignFormUpdate_PlaceholderName": "Название",
  "key_AdvertisingCampaignFormUpdate_LabelStatus": "Статус",
  "key_AdvertisingCampaignFormUpdate_PlaceholderStatus": "Статус",
  "key_AdvertisingCampaignFormUpdate_TagsTitle": "Теги",
  "key_AdvertisingCampaignFormUpdate_MediaFileTitle": "Медиа файл",
  "key_AdvertisingCampaignFormUpdate_GeneralTitle": "Информация",
  "key_AdvertisingCampaignFormUpdate_PlaybackSettingsTitle": "Настройка даты и времени воспроизведения контента",
  "key_AdvertisingCampaignFormUpdate_LabelDateOrDates": "Дата",
  "key_AdvertisingCampaignFormUpdate_LabelTime": "Время",
  "key_AdvertisingCampaignFormUpdate_LabelDateStart": "Старт",
  "key_AdvertisingCampaignFormUpdate_FormDate_Today": "Сегодня",
  "key_AdvertisingCampaignFormUpdate_FormDate_ThisMonth": "Этот месяц",
  "key_AdvertisingCampaignFormUpdate_LabelDateEnd": "Конец",
  "key_AdvertisingCampaignFormUpdate_LabelDateRange": "Период",
  "key_AdvertisingCampaignFormUpdate_PlaceholderSelectDay": "Выберите день",
  "key_AdvertisingCampaignFormUpdate_PlaceholderSelectStartTime": "Выберите время старта показа",
  "key_AdvertisingCampaignFormUpdate_PlaceholderSelectEndTime": "Выберите время окончания показа",
  "key_AdvertisingCampaignFormUpdate_PlaceholderSelectStartDay": "Выберите день старта показа",
  "key_AdvertisingCampaignFormUpdate_PlaceholderSelectEndDay": "Выберите день окончания показа",
  "key_AdvertisingCampaignFormUpdate_PlaceholderLimitTitle": "Настройка количества воспроизведений",
  "key_AdvertisingCampaignFormUpdate_LabelEveryDay": "Каждый день",
  "key_AdvertisingCampaignFormUpdate_LabelWorkDays": "Рабочие дни",
  "key_AdvertisingCampaignFormUpdate_LabelWeekend": "Выходные дни",
  "key_AdvertisingCampaignFormUpdate_LabelCustom": "Выбрать дни",
  "key_AdvertisingCampaignFormUpdate_LabelMonday": "пн",
  "key_AdvertisingCampaignFormUpdate_LabelTuesday": "вт",
  "key_AdvertisingCampaignFormUpdate_LabelWednesday": "ср",
  "key_AdvertisingCampaignFormUpdate_LabelThursday": "чт",
  "key_AdvertisingCampaignFormUpdate_LabelFriday": "пт",
  "key_AdvertisingCampaignFormUpdate_LabelSaturday": "сб",
  "key_AdvertisingCampaignFormUpdate_LabelSunday": "вс",
  "key_AdvertisingCampaignFormUpdate_LabelTotalLimit": "Всего показов",
  "key_AdvertisingCampaignFormUpdate_PlaceholderTotalLimit": "Всего показов",
  "key_AdvertisingCampaignFormUpdate_LabelDailyLimit": "Показов в день",
  "key_AdvertisingCampaignFormUpdate_PlaceholderDailyLimit": "Показов в день",

  // -- MultiFileUploader
  "MultiFileUploader_DragAndDropDescription": "Перетащите сюда несколько файлов или нажмите, чтобы выбрать файлы",

  // -- AccountFormOrganizationInfo
  "key_AccountFormOrganizationInfo_ITNLabel": "ИНН",
  "key_AccountFormOrganizationInfo_BankLabel": "Банк",
  "key_AccountFormOrganizationInfo_IECLabel": "КПП",
  "key_AccountFormOrganizationInfo_CurrentAccountLabel": "Расчётный счёт",
  "key_AccountFormOrganizationInfo_PSRNLabel": "ОГРН",
  "key_AccountFormOrganizationInfo_CorrespondingAccountLabel": "Корреспондирующий счёт",
  "key_AccountFormOrganizationInfo_BICLabel": "БИК",
  "key_AccountFormOrganizationInfo_NameLabel": "Название организации",
  "key_AccountFormOrganizationInfo_ActualAddressLabel": "Фактический адрес",
  "key_AccountFormOrganizationInfo_LegalAddressLabel": "Юридический адрес",
  "key_AccountFormOrganizationInfo_CEOLabel": "Гениральный директор",

  // -- PageAccountSettings
  "key_PageAccountSettings_ProfileInfo": "Профайл",
  "key_PageAccountSettings_Password": "Управление паролем",
  "key_PageAccountSettings_OrganizationInfo": "Информация об организации",

  // -- AccountFormProfileInfo
  "key_AccountFormProfileInfo_FirstNameLabel": "Имя",
  "key_AccountFormProfileInfo_LastNameLabel": "Фамилия",
  "key_AccountFormProfileInfo_UsernameLabel": " Логин",
  "key_AccountFormProfileInfo_EmailLabel": "Email",
  "key_AccountFormProfileInfo_PhoneLabel": " Телефон",

  // -- TransactionCardList
  "key_TransactionCardList_OrderNumber": "# Транзакции",
  "key_TransactionCardList_Date": "Дата",
  "key_TransactionCardList_Description": "Основание",
  "key_TransactionCardList_Amount": "Сумма (руб)",

  // -- key_AdFormTagsStatisticsInfo
  "key_AdFormTagsStatisticsInfo_TagName": "Тэг",
  "key_AdFormTagsStatisticsInfo_AdImpressions": "Кол-во показов",

// -- key_PageCustomerAdList
  "key_PageCustomerAdList_FilterSearch": "Поиск по имени",
  "key_PageCustomerAdList_FilterStatusAll": "Показать все",
  "key_PageCustomerAdList_FilterStatusCreated": "Новые",
  "key_PageCustomerAdList_FilterStatusAccepted": "Принятые",
  "key_PageCustomerAdList_FilterStatusRejected": "Не принятые",
  "key_PageCustomerAdList_FilterStatusDone": "Выполненные",
};

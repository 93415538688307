import React from "react";
import {connect} from "react-redux";
import {NavLink} from "react-router-dom";
import {Preloader} from "../components";
import {Media} from "../actions";
import {getAccount, getMediaFiles} from "../selectors";
import {I18n} from "../utils/i18n";
import {MediaFileCardList, MultiFileUploader, Pagination} from "../containers";
import qs from "query-string";
import {serialize} from "../utils/helpers";
import {LocalStorage} from "../utils/storage";

class MediaPageList extends React.Component {
  constructor(props) {
    super(props);

    let query = qs.parse(this.props.history.location.search);

    this.state = {
      total: 0,
      page: parseInt(query.page, 10) || 1,
      limit: parseInt(query.limit, 10) || 50,
      pages: 1,
      maxPages: 5,
      pending: true,
    };
  }

  componentDidMount() {
    this.props.dispatch(Media.FileList({
      page: this.state.page,
      limit: this.state.limit
    }))
      .then((res) => {
        const pages = (res.total > this.state.limit) ? Math.ceil(res.total / this.state.limit) : 1;

        if (res.page > pages) return this.handlePageChange(pages);

        this.setState({total: res.total, page: res.page, pages: pages, pending: false})
      })
      .catch(() => this.setState({pending: false}));
  }

  handlePageChange = (page) => {
    let query = qs.parse(this.props.history.location.search);
    query.page = page;
    this.props.history.push({...this.props.location, search: "?" + serialize(query || {})});

    this.props.dispatch(Media.FileList({
      page: page,
      limit: this.state.limit
    }))
      .then((res) => {
        const pages = (res.total > this.state.limit) ? Math.ceil(res.total / this.state.limit) : 1;

        if (res.page > pages) return this.handlePageChange(pages);

        this.props.history.push({...this.props.location, search: "?" + serialize(query || {})});
        this.setState({total: res.total, page: res.page, pages: pages})
      })
  };

  handleRemove = (file) => {
    this.props.dispatch(Media.FileRemove(file))
  };

  handleEvent = (event) => {
    this.handlePageChange(this.state.page)
  };

  render() {
    if (this.state.pending) return (
      <section className="container d-flex justify-content-center align-items-center vh-100">
        <Preloader/>
      </section>
    );

    const {files} = this.props;

    return (
      <section className="container content">

        <div className="subnav-panel">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><NavLink to={"/"}>{I18n.t('key_Home')}</NavLink></li>
            <li className="breadcrumb-item active">{I18n.t('key_Media')}</li>
          </ol>
        </div>

        <div className="row">
          <div className="col-9">

            <div className="card">
              <div className="card-body">

                <MediaFileCardList files={files}
                                   onRemove={this.handleRemove}/>

                <div className="mt-4">
                  <Pagination maxPages={this.state.maxPages}
                              currentPage={this.state.page}
                              numPages={this.state.pages}
                              change={this.handlePageChange}/>
                </div>

              </div>
            </div>


          </div>

          <div className="col-3">
            <div className="d-flex justify-content-center">
              <MultiFileUploader accept=".mkv,.mp4"
                                 onMessage={this.handleEvent}
                                 uploadURI={[window._env_.API_HOST, "media", "upload"].join("/") + "?x-access-token=" + LocalStorage().get("token")}/>
            </div>
          </div>
        </div>

      </section>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    files: getMediaFiles(state),
    account: getAccount(state),
  }
};

export default connect(mapStateToProps)(MediaPageList);


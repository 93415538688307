import React from "react";
import {connect} from "react-redux";
import {NavLink, withRouter} from "react-router-dom";
import {AdvertisingCampaignFormCreate} from "./../containers";
import {I18n} from "../utils/i18n";
import {AdvertisingCampaign} from "../actions";

class AdvertisingCampaignPageCreate extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  handleHideAdvertisingCampaignCreateForm = () => this.props.history.push("/campaign");

  handleSubmitCreateAdvertisingCampaign = (data) => {
    return this.props.dispatch(AdvertisingCampaign.Create(data))
      .then((res) => this.props.history.push(`/campaign/${res.id}`))
  };

  render() {
    return (
      <section className="container content">

        <div className="subnav-panel">
          <div>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <NavLink to={"/"}>{I18n.t('key_Home')}</NavLink>
              </li>
              <li className="breadcrumb-item cursor-pointer">
                <NavLink to="/campaign"> {I18n.t('key_AdvertisingCampaign')}</NavLink>
              </li>
              <li className="breadcrumb-item active">
                {I18n.t('key_AdvertisingCampaignPageCreate_CreateNew')}
              </li>
            </ol>
          </div>
        </div>

        <div className="card">
          <div className="card-body">

            <div className="mb-4">
              <h2>{I18n.t('key_AdvertisingCampaignPageCreate_Title')}</h2>
            </div>

            <AdvertisingCampaignFormCreate submit={this.handleSubmitCreateAdvertisingCampaign}
                                           cancel={this.handleHideAdvertisingCampaignCreateForm}/>
          </div>
        </div>

      </section>
    )
  }
}

const mapStateToProps = (state, props) => props;

export default withRouter(connect(mapStateToProps)(AdvertisingCampaignPageCreate));

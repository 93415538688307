import React from "react";
import PropTypes from 'prop-types'
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {I18n} from "../utils/i18n";
import moment from "moment/moment";
import {parseTime} from "../utils/helpers";

const timeFormat = 'HH:mm';

class AdvertisingCampaignCardList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mapTags: props.mapTags || {},
    };
  }

  handleSelect = (e, item) => {
    e.stopPropagation();
    this.props.onSelect(item);
  };

  handleRemove = (e, item) => {
    e.stopPropagation();
    this.props.onRemove(item);
  };

  showTags = (list) => {
    let res = [];
    list.forEach(item => {
      res.push(this.state.mapTags[item.id])
    })
    return res.join(", ");
  }

  render() {
    const {campaigns} = this.props;

    return (
      <div className="devices-list__table">

        <table className="table table-hover">
          <thead>
          <tr>
            <th scope="col" style={{width: "70px"}}>{I18n.t('key_AdvertisingCampaignCardList_TableHeaderStatus')}</th>
            <th scope="col" style={{width: "60%"}}>{I18n.t('key_AdvertisingCampaignCardList_TableHeaderName')}</th>
            <th scope="col"
                style={{width: "40%"}}>
              {I18n.t('key_AdvertisingCampaignCardList_TableHeaderInterval')}
            </th>
            <th scope="col"
                style={{minWidth: "10px"}}>
              {I18n.t('key_AdvertisingCampaignCardList_TableHeaderTags')}
            </th>
            <th scope="col"
                style={{minWidth: "90px"}}>{I18n.t('key_AdvertisingCampaignCardList_TableHeaderCreated')}</th>
            <th scope="col" style={{width: "5px"}}/>
          </tr>
          </thead>
          <tbody>

          {
            campaigns.map((item, index) => {
              return (
                <tr key={index} className="cursor-pointer">
                  <td style={{verticalAlign: "middle", width: "70px"}}>
                    {(item.status.status === "created") &&
                      <span className="badge badge-primary">{I18n.t('key_StatusAdCreated')}</span>}
                    {(item.status.status === "accepted") &&
                      <span className="badge badge-warning">{I18n.t('key_StatusAdAccepted')}</span>}
                    {(item.status.status === "rejected") &&
                      <span className="badge badge-danger">{I18n.t('key_StatusAdRejected')}</span>}
                    {(item.status.status === "done") &&
                      <span className="badge badge-success">{I18n.t('key_StatusAdDone')}</span>}
                  </td>
                  <td style={{verticalAlign: "middle"}} onClick={e => this.handleSelect(e, item)}>
                    {item.meta.name}
                  </td>
                  <td style={{verticalAlign: "middle"}} onClick={e => this.handleSelect(e, item)}>
                    {new Date(item.spec.start).toLocaleString("ru-RU", {
                      year: "numeric",
                      month: "2-digit",
                      day: "numeric",
                    })} - {new Date(item.spec.end).toLocaleString("ru-RU", {
                      year: "numeric",
                      month: "2-digit",
                      day: "numeric",
                    })} ({moment(parseTime(item.spec.start_time)).format(timeFormat)} - {moment(parseTime(item.spec.end_time)).format(timeFormat)})
                  </td>
                  <td style={{verticalAlign: "middle", textAlign: "center"}}>
                    <i
                      className="fas fa-info-circle"
                      data-toggle="popover"
                      data-placement="left"
                      title={I18n.t('key_AdvertisingCampaignCardList_Tags')}
                      data-content={this.showTags(item.spec?.tags || [])}/>
                  </td>
                  <td style={{verticalAlign: "middle"}}>
                    {new Date(item.meta.created).toLocaleString("ru-RU", {
                      year: "numeric",
                      month: "2-digit",
                      day: "numeric",
                    })}
                  </td>
                  <td style={{verticalAlign: "middle"}} onClick={e => e.stopPropagation()}>
                    <div className="btn-group text-center cursor-pointer">
                      <div style={{width: "20px"}} data-toggle="dropdown">
                        <i className="fas fa-ellipsis-v cursor-pointer px-1"/>
                      </div>
                      <div className="dropdown-menu dropdown-menu-right">
                        <div className="dropdown-item text-danger cursor-pointer"
                             onClick={e => this.handleRemove(e, item)}>
                          {I18n.t('key_Remove')}
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              )
            })
          }
          </tbody>
        </table>

      </div>
    );
  }
}

AdvertisingCampaignCardList.defaultProps = {
  campaigns: [],
  mapTags: {},
};

AdvertisingCampaignCardList.propTypes = {
  campaigns: PropTypes.array,
  onSelect: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  mapTags: PropTypes.object,
};

const mapStateToProps = (state, props) => props;

export default withRouter(connect(mapStateToProps)(AdvertisingCampaignCardList));

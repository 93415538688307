import {Media} from '../../api';
import {normalize} from "normalizr";
import mediaFilesSchema from "../../schemas/media";

import {MEDIA_FILE_REMOVE_FAILURE, MEDIA_FILE_REMOVE_SUCCESS} from '../../constants';

const SuccessAction = (payload) => ({
  type: MEDIA_FILE_REMOVE_SUCCESS,
  payload: normalize([payload], [mediaFilesSchema])
});

const FailureAction = (payload) => ({
  type: MEDIA_FILE_REMOVE_FAILURE,
  payload
});

export default (file) => (dispatch) => {
  return new Promise((resolve, reject) => {
    Media.FileRemove(file.id)
      .then((response) => {
        dispatch(SuccessAction(response));
        resolve(file)
      })
      .catch(error => {
        dispatch(FailureAction(error));
        reject(error)
      })
  })
};
import React from "react";
import {connect} from "react-redux";
import {NavLink, withRouter} from "react-router-dom";
import {AdvertisingCampaignCardList, Pagination} from "./../containers";
import {I18n} from "../utils/i18n";
import {AdvertisingCampaign} from "../actions";
import {getAdvertisementCampaigns} from "../selectors";
import qs from "query-string";
import {serialize} from "../utils/helpers";
import * as api from "../api";
import {Preloader} from "../components";
import debounce from "lodash.debounce";

class AdvertisingCampaignPageList extends React.Component {

  constructor(props) {
    super(props);

    let query = qs.parse(this.props.history.location.search);

    this.state = {
      pending: true,
      tags: [],
      mapTags: {},
      total: 0,
      page: parseInt(query.page, 10) || 1,
      limit: parseInt(query.limit, 10) || 50,
      filter: query.filter || '',
      status: query.status || '',
      pages: 1,
      maxPages: 5,
    };
  }

  componentDidMount() {
    Promise.all([
      this.props.dispatch(AdvertisingCampaign.List({
        page: this.state.page,
        limit: this.state.limit,
        filter: this.state.filter,
        status: this.state.status,
      })),
      api.Tag.List()
    ])
      .then((res) => {
        const pages = (res[0].total > this.state.limit) ? Math.ceil(res[0].total / this.state.limit) : 1;

        if (res[0].page > pages) return this.handlePageChange(pages);

        let mapTags = {};
        (res[1]).forEach(item => {
          mapTags[item.id] = item.name;
        })

        this.setState({total: res[0].total, page: res[0].page, pages: pages, pending: false, tags: res[1], mapTags: mapTags})
      })
      .catch(() => this.setState({pending: false}));
  }

  handlePageChange = (page) => {

    let query = qs.parse(this.props.history.location.search);
    query.page = page;
    this.props.history.push({...this.props.location, search: "?" + serialize(query || {})});

    this.props.dispatch(AdvertisingCampaign.List({
      page: page,
      limit: this.state.limit,
      filter: this.state.filter,
      status: this.state.status,
    }))
      .then((res) => {
        const pages = (res.total > this.state.limit) ? Math.ceil(res.total / this.state.limit) : 1;

        if (res.page > pages) return this.handlePageChange(pages);

        this.props.history.push({...this.props.location, search: "?" + serialize(query || {})});
        this.setState({total: res.total, page: res.page, pages: pages, pending: false})
      })
      .catch(() => this.setState({pending: false}));
  };

  handleFilterStatusChange = (e) => {
    const target = e.target;
    const value = target.value;

    let query = qs.parse(this.props.history.location.search);
    query.status = value;
    this.props.history.push({...this.props.location, search: "?" + serialize(query || {})});

    this.props.dispatch(AdvertisingCampaign.List( {
      page: this.state.page,
      limit: this.state.limit,
      filter: this.state.filter,
      status: value,
    }))
      .then((res) => {
        const pages = (res.total > this.state.limit) ? Math.ceil(res.total / this.state.limit) : 1;

        if (res.page > pages) return this.handlePageChange(pages);

        this.props.history.push({...this.props.location, search: "?" + serialize(query || {})});
        this.setState({status: value, total: res.total, page: res.page, pages: pages, pending: false})
      })
      .catch((e) => {
        if (e.code === 404) return this.props.history.push("/404");
        this.setState({pending: false})
        return Promise.reject(e);
      });
  }

  handleFilterChange = debounce((value) => {
    let query = qs.parse(this.props.history.location.search);
    query.filter = value;
    this.props.history.push({...this.props.location, search: "?" + serialize(query || {})});

    this.props.dispatch(AdvertisingCampaign.List( {
      page: this.state.page,
      limit: this.state.limit,
      status: this.state.status,
      filter: value,
    }))
      .then((res) => {
        const pages = (res.total > this.state.limit) ? Math.ceil(res.total / this.state.limit) : 1;

        if (res.page > pages) return this.handlePageChange(pages);

        this.props.history.push({...this.props.location, search: "?" + serialize(query || {})});
        this.setState({filter: value, total: res.total, page: res.page, pages: pages, pending: false})
      })
      .catch((e) => {
        if (e.code === 404) return this.props.history.push("/404");
        this.setState({pending: false})
        return Promise.reject(e);
      });
  }, 300)

  handleSelect = (item) => {
    this.props.history.push(`/campaign/${item.meta.id}`)
  };

  handleSubmitRemoveAdvertisingCampaign = (item) => {
    return this.props.dispatch(AdvertisingCampaign.Remove(item))
  };

  sortableByDate = (obj, sort) => {
    // convert object into array
    let sortable = [];
    for (let key in obj)
      if (obj.hasOwnProperty(key)) sortable.push(obj[key]);

    // sort items by value
    sortable.sort(function (a, b) {
      let x = new Date(a.meta.created);
      let y = new Date(b.meta.created);

      return (!sort || sort.toLowerCase() === 'asc') ? y - x : x - y;
    });

    return sortable;
  };

  render() {
    window.$(() => window.$('[data-toggle="popover"]').popover({trigger: 'hover'}));

    if (this.state.pending) return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <Preloader/>
      </div>
    );

    const {campaigns} = this.props;

    let sortable = this.sortableByDate(campaigns, 'asc');

    return (
      <section className="container content">

        <div className="subnav-panel">
          <div>
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><NavLink to={"/"}>{I18n.t('key_Home')}</NavLink></li>
              <li className="breadcrumb-item active">{I18n.t('key_AdvertisingCampaign')}</li>
            </ol>
          </div>
        </div>

        <div>
          <div className="mb-5">
            <div className="mb-4 pb-2">
              <div className="clearfix">
                <div className="float-left">
                  <h2>{I18n.t('key_AdvertisingCampaignPageList_Title')}</h2>
                </div>
                <div className="float-right">
                  <NavLink to="/campaign/new" className="btn btn-primary">
                    <i className="fas fa-plus-circle"/> {I18n.t('key_Create')}
                  </NavLink>
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between mb-4">
                  <div>
                    <input type="text"
                           style={{minWidth: "400px"}}
                           className="form-control form-control-sm"
                           placeholder={I18n.t('key_PageCustomerAdList_FilterSearch')}
                           name="filter"
                           defaultValue={this.state.filter}
                           onChange={e => this.handleFilterChange(e.target.value)}/>
                  </div>
                  <div style={{maxWidth: "250px"}}>
                    <select className="form-control form-control-sm" id="status"
                            name="status"
                            value={this.state.status}
                            onChange={this.handleFilterStatusChange}>
                      <option value="">{I18n.t('key_PageCustomerAdList_FilterStatusAll')}</option>
                      <option value="created">{I18n.t('key_PageCustomerAdList_FilterStatusCreated')}</option>
                      <option value="accepted">{I18n.t('key_PageCustomerAdList_FilterStatusAccepted')}</option>
                      <option value="rejected">{I18n.t('key_PageCustomerAdList_FilterStatusRejected')}</option>
                      <option value="done">{I18n.t('key_PageCustomerAdList_FilterStatusDone')}</option>
                    </select>
                  </div>
                </div>

                <AdvertisingCampaignCardList campaigns={sortable}
                                             onRemove={this.handleSubmitRemoveAdvertisingCampaign}
                                             onSelect={this.handleSelect}
                                             mapTags={this.state.mapTags}
                />

                <div className="mt-4">
                  <Pagination maxPages={this.state.maxPages}
                              currentPage={this.state.page}
                              numPages={this.state.pages}
                              change={this.handlePageChange}/>
                </div>
              </div>
            </div>

          </div>
        </div>

      </section>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    campaigns: getAdvertisementCampaigns(state),
  }
};

export default withRouter(connect(mapStateToProps)(AdvertisingCampaignPageList));

import React from "react";
import {connect} from "react-redux";
import {NavLink} from "react-router-dom";

import {Preloader, TransactionCardList} from "../components";
import {getAccount} from "../selectors";
import * as api from "../api";
import {I18n} from "../utils/i18n";
import {Pagination} from "../containers";
import qs from "query-string";
import {serialize} from "../utils/helpers";

class PageAccountTransactionInfo extends React.Component {
  constructor(props) {
    super(props);

    let query = qs.parse(this.props.history.location.search);

    this.state = {
      data: {
        amount: "",
      },
      error: {
        amount: "",
      },
      total: 0,
      page: parseInt(query.page, 10) || 1,
      limit: parseInt(query.limit, 10) || 50,
      items: [],
      pages: 1,
      maxPages: 5,
      pending: true,
    };
  }

  componentDidMount() {
    return api.Account.TransactionList({
      page: this.state.page,
      limit: this.state.limit
    })
      .then((res) => {
        const pages = (res.total > this.state.limit) ? Math.ceil(res.total / this.state.limit) : 1;

        if (res.page > pages) return this.handlePageChange(pages);

        this.setState({total: res.total, page: res.page, pages: pages, items: res.items, pending: false})
      })
      .catch(() => this.setState({pending: false}));
  }

  handlePageChange = (page) => {
    api.Account.TransactionList({
      page: page,
      limit: this.state.limit
    })
      .then((res) => {
        const pages = (res.total > this.state.limit) ? Math.ceil(res.total / this.state.limit) : 1;

        if (res.page > pages) return this.handlePageChange(pages);

        let query = qs.parse(this.props.match.params.customer, this.props.history.location.search);
        query.page = res.page;
        this.props.history.push({...this.props.location, search: "?" + serialize(query || {})});

        this.setState({total: res.total, page: res.page, pages: pages, items: res.items, pending: false})
      })
      .catch(() => this.setState({pending: false}));
  };

  handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    let value = target.type === 'checkbox' ? target.checked : target.value;

    let patch = {};
    switch (name) {
      case "amount":
        patch = {data: {...this.state.data, [name]: value}};
        break;
      default:
    }

    this.setState(patch)
  }

  handleTransactionCreate = () => {
    api.Account.InvoiceCreate({
      amount: parseFloat(this.state.data.amount.replace(",", ".")),
    })
      .catch((e) => console.error(e));
  }

  handleDisabled = () => {
    return !this.state.data.amount || parseFloat(this.state.data.amount) === 0
  }

  render() {
    if (this.state.pending) return (
      <section className="container d-flex justify-content-center align-items-center vh-100">
        <Preloader/>
      </section>
    );

    const {account} = this.props;

    return (
      <section className="container content">

        <div className="subnav-panel">
          <div className="d-flex">
            <div>
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><NavLink to={"/"}>{I18n.t('key_Home')}</NavLink></li>
                <li className="breadcrumb-item active">{I18n.t('key_Transactions')}</li>
              </ol>
            </div>
          </div>
        </div>

        <div className="row justify-content-end">
          <div className="col-6">
            <div className="alert alert-light text-right" role="alert">
              <div className="d-flex">
                <div className="mr-auto">
                  <span style={{fontSize: "24px"}}><strong>{I18n.t('key_CustomerBalance')}:</strong></span>
                </div>
                <div>
                  <span className={`badge badge-pill badge-${account.balance > 0 ? "success" : "danger"}`}
                        style={{fontSize: "24px"}}>{account.balance.toFixed(2)} {I18n.t('key_Rub')}.</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-body">

            <div className="input-group mb-4">
              <div className="input-group-prepend">
                <input
                  placeholder="Сумма"
                  className={`form-control form-control-sm ${this.state.error.amount ? 'is-invalid' : ''}`}
                  type="text"
                  id="amount" name="amount"
                  onChange={this.handleChange}
                  value={this.state.data.amount}/>
              </div>
              <div className="input-group-append">
                <button type="submit" className="btn btn-sm btn-primary"
                        onClick={this.handleTransactionCreate}
                        disabled={this.handleDisabled()}>
                  {I18n.t('key_NewTransaction')}
                </button>
              </div>
            </div>

            <TransactionCardList items={this.state.items}/>

            <div className="mt-4">
              <Pagination maxPages={this.state.maxPages}
                          currentPage={this.state.page}
                          numPages={this.state.pages}
                          change={this.handlePageChange}/>
            </div>
          </div>
        </div>

      </section>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    account: getAccount(state),
  }
};

export default connect(mapStateToProps)(PageAccountTransactionInfo);


import {download, requestJSON} from "../utils/request";
import {serialize} from "../utils/helpers";

const api_host = window._env_.API_HOST;

class AdvertisingCampaign {

  static AdvertisingCampaignCreate(data) {
    let uri = [api_host, "ac"].join("/");
    return requestJSON("POST", uri, data, true);
  }

  static AdvertisingCampaignFetch(id) {
    let uri = [api_host, "ac", id].join("/");
    return requestJSON("GET", uri, null, true);
  }

  static AdvertisingCampaignList(opts) {
    let uri = [api_host, "ac"].join("/");
    opts = opts || {};
    let qs = serialize({
      page: parseInt(opts.page, 10) || 1,
      limit: parseInt(opts.limit, 10) || 10,
      status: opts.status || '',
      filter: opts.filter || '',
    });
    uri += !!qs.length ? "?" + qs : "";
    return requestJSON("GET", uri, null, true);
  }

  static AdvertisingCampaignUpdate(id, data) {
    let uri = [api_host, "ac", id].join("/");
    return requestJSON("PUT", uri, data, true);
  }

  static AdvertisingCampaignRemove(id) {
    let uri = [api_host, "ac", id].join("/");
    return requestJSON("DELETE", uri, null, true);
  }

  static AdvertisingCampaignTagsStatisticsList(id) {
    let uri = [api_host, "ac", id, "statistic", "tags"].join("/");
    return requestJSON("GET", uri, null, true);
  }

  static AdvertisingCampaignTagsStatisticsExcelExport(id, name) {
    let uri = [api_host, "ac", id, "statistic", "tags"].join("/");
    let headers = {}
    headers["Content-Type"] = "application/vnd.ms-excel";
    return download(uri, name + `.xlsx`, true, headers);
  }

}

export default AdvertisingCampaign

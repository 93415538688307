import {requestJSON} from "../utils/request";
import {serialize} from "../utils/helpers";
import {LocalStorage} from "../utils/storage";

const api_host = window._env_.API_HOST;

class Account {

  static Fetch() {
    let uri = [api_host, "customer"].join("/");
    return requestJSON("GET", uri, null, true);
  }

  static Create(body, opts) {
    let uri = [api_host, "customer"].join("/");
    opts = opts || {};
    if (Object.keys(opts).length) {
      let qs = serialize(opts)
      uri += !!qs.length ? "?" + qs : "";
    }
    return requestJSON("POST", uri, body);
  }

  static ProfileFetch() {
    let uri = [api_host, "customer", "profile"].join("/");
    return requestJSON("GET", uri, null, true);
  }

  static ProfileUpdate(data) {
    let uri = [api_host, "customer", "profile"].join("/");
    return requestJSON("PUT", uri, data, true);
  }

  static PasswordUpdate(body) {
    let uri = [api_host, "customer", "password"].join("/");
    return requestJSON("PUT", uri, body, true);
  }

  static PasswordChange(token, password) {
    let uri = [api_host, "customer", "password", "recovery", token].join("/");
    let body = {password: password};
    return requestJSON("PUT", uri, body, false);
  }

  static PasswordRecovery(login) {
    let uri = [api_host, "customer", "password", "recovery"].join("/") + "?login=" + login;
    return requestJSON("POST", uri, null, false);
  }

  static TransactionList(opts) {
    let uri = [api_host, "customer", "transaction"].join("/");
    opts = opts || {};
    let qs = serialize({
      page: parseInt(opts.page, 10) || 1,
      limit: parseInt(opts.limit, 10) || 10,
    });
    uri += !!qs.length ? "?" + qs : "";
    return requestJSON("GET", uri, null, true);
  }

  static InvoiceCreate(data) {
    let uri = [api_host, "customer", "invoice"].join("/");
    return fetch(uri, {
      method: 'POST',
      headers: {'Authorization': ["Bearer", LocalStorage().get("token")].join(" ")},
      body: JSON.stringify(data)
    }).then((response) => {
      const filename = response.headers.get('Content-Disposition').split('filename=')[1];
      response.blob().then(blob => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = filename;
        a.click();
      })
    })
  }
}

export default Account

import {AdvertisingCampaign} from "../../api";
import {ADVERTISING_CAMPAIGN_LIST_FETCH_FAILURE, ADVERTISING_CAMPAIGN_LIST_FETCH_SUCCESS} from '../../constants';
import {normalize} from "normalizr";
import advertisingCampaignSchema from "../../schemas/advertising_campaign";


const SuccessAction = (payload) => ({
  type: ADVERTISING_CAMPAIGN_LIST_FETCH_SUCCESS,
  payload: normalize(payload, [advertisingCampaignSchema])
});

const FailureAction = (payload) => ({
  type: ADVERTISING_CAMPAIGN_LIST_FETCH_FAILURE,
  payload
});

export default (opts) => (dispatch) => {
  return new Promise((resolve, reject) => {
    AdvertisingCampaign.AdvertisingCampaignList(opts)
      .then(response => {
        dispatch(SuccessAction(response.items));
        resolve(response);
      })
      .catch(error => {
        dispatch(FailureAction(error));
        reject(error);
      });
  });
};
import {createReducer} from "../utils/reducer";
import * as storage from "../utils/storage";
import {fromJS} from 'immutable'

import {accountReducer} from "./account";
import {sessionReducer} from "./session";
import {mediaReducer} from "./media";
import {campaignAdvertisingReducer} from "./advertising_campaign";

const initialState = fromJS(
  {
    session: {
      token: storage.LocalStorage().get('token')
    }
  }
);

const reducers = Object.assign(sessionReducer, accountReducer, mediaReducer, campaignAdvertisingReducer);

export default createReducer(initialState, reducers)
import {requestJSON} from "../utils/request";

const api_host = window._env_.API_HOST;

class Tag {

  static List() {
    let uri = [api_host, "tag"].join("/");
    return requestJSON("GET", uri, null, true);
  }

}

export default Tag
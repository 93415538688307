import React from "react";

import "./style.css"
import PropTypes from "prop-types";


class InputSearch extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      placeholder: typeof props.placeholder === "string" ? props.placeholder : "",
      name: typeof props.name === "string" ? props.name : "search",
      btnOpenName: typeof props.btnOpenName === "string" ? props.btnOpenName : "Open",
      btnOpenClassName: typeof props.btnOpenClassName === "string" ? props.btnOpenClassName : "btn btn-primary",
      btnCloseName: typeof props.btnCloseName === "string" ? props.btnCloseName : "Close",
      btnCloseClassName: typeof props.btnCloseClassName === "string" ? props.btnCloseClassName : "btn btn-outline-light",
      btnOkName: typeof props.btnOkName === "string" ? props.btnOkName : "Ok",
      btnOkClassName: typeof props.btnOkClassName === "string" ? props.btnOkClassName : "btn btn-primary",
      className: typeof props.className === "string" ? props.className : "",
      content: typeof props.content === "object" ? props.content : null,
      onOk: typeof props.onOk === "function" ? props.onOk : null,
      onCancel: typeof props.onCancel === "function" ? props.onCancel : null,
      disabled: typeof props.disabled === "boolean" ? props.disabled : false,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({content: nextProps.content});
  };

  render() {
    return (
      <div className={`modal-dialog-container ${this.state.className}`}>
        <button type="button" className={this.state.btnOpenClassName} data-toggle="modal"
                data-target="#modalDialog">
          {this.state.btnOpenName}
        </button>

        <div className="modal fade" id="modalDialog" tabIndex="-1" role="dialog" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">

              <div className="modal-header">

              </div>

              <div className="modal-body">
                {this.state.content}
              </div>

              <div className="modal-footer">
                <div className="clearfix">
                  <div className="float-left">
                    <button type="button"
                            className={this.state.btnCloseClassName}
                            onClick={this.state.onCancel}
                            data-dismiss="modal">{this.state.btnCloseName}</button>
                  </div>
                  <div className="float-right">
                    <button type="button"
                            className={this.state.btnOkClassName}
                            disabled={this.state.disabled}
                            onClick={this.state.onOk}>{this.state.btnOkName}</button>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    )
  }
}

InputSearch.propTypes = {
  onClose: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  btnCloseClassName: PropTypes.string,
  btnCloseName: PropTypes.string,
  btnOkClassName: PropTypes.string,
  btnOkName: PropTypes.string,
  className: PropTypes.string,
  btnOpenName: PropTypes.string,
  btnOpenClassName: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  content: PropTypes.node,
  disabled: PropTypes.bool,
};

export default InputSearch;

import React from "react"
import {connect} from "react-redux"
import PropTypes from 'prop-types'

import "./style.css"

class BankSuggestionsByName extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      search: "",
      error: "",
      value: props.value || "",
      list: []
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      value: nextProps.value || "",
      search: nextProps.search || ""
    })
  };

  handleSelect = (e, val) => {
    let event = new Event(this.props.name);
    Object.defineProperty(event, 'target', {
      writable: false,
      value: {name: this.props.name, value: val},
    });
    this.props.onChange(event)
    this.setState({value: val.name, list: []})
  }

  handleSearch = (e) => {

    e.preventDefault();

    let search = e.target.value;

    clearTimeout(this.handleSearch.timeout);
    this.setState({value: search, list: [], error: ""})

    this.handleSearch.timeout = setTimeout(() => {
      this.setState({pending: true, list: [], error: ""});

      const url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/bank";
      const options = {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "Authorization": "Token " + this.props.token
        },
        body: JSON.stringify({query: search})
      }

      fetch(url, options)
        .then(response => response.json())
        .then(result => {
          let res = [];
          try {
            result.suggestions.forEach((d) => {
              const data = d.data;
              res.push({
                name: data.name?.short || data.name?.payment || "",
                itn: data.inn || "",
                bic: data.bic || "",
                legal_address: data.address?.unrestricted_value || "",
                correspondent_account: data.correspondent_account || "",
              })
            })
          } catch (e) {
            console.error(e)
          }

          this.setState({list: res})

        })
        .catch(error =>
          this.setState({error: error}));

    }, 1000);
  };

  handleSubmit = (e) => {
    this.props.submit(e)
      .then(() => this.setState({pending: false}))
      .catch(() => this.setState({pending: false}))
  };

  render() {
    return (
      <div style={{position: "relative"}}>
        <input className={this.props.className} type="text" id={this.props.id} name={this.props.name}
               value={this.state.value}
               onChange={this.handleSearch}/>
        {
          (!!this.state.list.length) && (
            <div style={{position: "absolute", zIndex: 999, background: "white"}}>
              {
                this.state.list.map((item, index) => {
                  return (
                    <div key={index} className="p-2 cursor-pointer" style={{width: "100%"}}
                         onClick={(e) => this.handleSelect(e, item)}>
                      <div>{item.name}</div>
                      <div className="small">{item.itn}</div>
                      <div className="small">{item.legal_address}</div>
                    </div>
                  )
                })
              }
            </div>
          )
        }
      </div>
    );
  }
}

BankSuggestionsByName.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  value: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
};

const mapStateToProps = (state, props) => props;

export default connect(mapStateToProps)(BankSuggestionsByName);

// ==============================================================================================
// SESSION ======================================================================================
// ==============================================================================================
export const SESSION_CREATE_SUCCESS = 'SESSION_CREATE_SUCCESS';
export const SESSION_CREATE_FAILURE = 'SESSION_CREATE_FAILURE';

export const SESSION_SET_SUCCESS = 'SESSION_SET_SUCCESS';

export const SESSION_REMOVE_SUCCESS = 'SESSION_REMOVE_SUCCESS';

// ==============================================================================================
// ACCOUNT ======================================================================================
// ==============================================================================================

export const ACCOUNT_CREATE_SUCCESS = 'ACCOUNT_CREATE_SUCCESS';
export const ACCOUNT_CREATE_FAILURE = 'ACCOUNT_CREATE_FAILURE';

export const ACCOUNT_FETCH_SUCCESS = 'ACCOUNT_FETCH_SUCCESS';
export const ACCOUNT_FETCH_FAILURE = 'ACCOUNT_FETCH_FAILURE';

export const ACCOUNT_LIST_FETCH_SUCCESS = 'ACCOUNT_LIST_FETCH_SUCCESS';
export const ACCOUNT_LIST_FETCH_FAILURE = 'ACCOUNT_LIST_FETCH_FAILURE';

export const ACCOUNT_UPDATE_SUCCESS = 'ACCOUNT_UPDATE_SUCCESS';
export const ACCOUNT_UPDATE_FAILURE = 'ACCOUNT_UPDATE_FAILURE';

export const ACCOUNT_CURRENT_SET_SUCCESS = 'ACCOUNT_CURRENT_SET_SUCCESS';

export const ACCOUNT_PROFILE_FETCH_SUCCESS = 'ACCOUNT_PROFILE_FETCH_SUCCESS';
export const ACCOUNT_PROFILE_FETCH_FAILURE = 'ACCOUNT_PROFILE_FETCH_FAILURE';

export const ACCOUNT_PROFILE_UPDATE_SUCCESS = 'ACCOUNT_PROFILE_UPDATE_SUCCESS';
export const ACCOUNT_PROFILE_UPDATE_FAILURE = 'ACCOUNT_PROFILE_UPDATE_FAILURE';

export const ACCOUNT_PASSWORD_UPDATE_SUCCESS = 'ACCOUNT_PASSWORD_UPDATE_SUCCESS';
export const ACCOUNT_PASSWORD_UPDATE_FAILURE = 'ACCOUNT_PASSWORD_UPDATE_FAILURE';

export const ACCOUNT_PASSWORD_RECOVERY_SUCCESS = 'ACCOUNT_PASSWORD_RECOVERY_SUCCESS';
export const ACCOUNT_PASSWORD_RECOVERY_FAILURE = 'ACCOUNT_PASSWORD_RECOVERY_FAILURE';

export const ACCOUNT_PASSWORD_CHANGE_SUCCESS = 'ACCOUNT_PASSWORD_CHANGE_SUCCESS';
export const ACCOUNT_PASSWORD_CHANGE_FAILURE = 'ACCOUNT_PASSWORD_CHANGE_FAILURE';

// ==============================================================================================
// MEDIA FILE ===================================================================================
// ==============================================================================================
export const MEDIA_FILE_LIST_FETCH_SUCCESS = 'MEDIA_FILE_LIST_FETCH_SUCCESS';
export const MEDIA_FILE_LIST_FETCH_FAILURE = 'MEDIA_FILE_LIST_FETCH_FAILURE';

export const MEDIA_FILE_REMOVE_SUCCESS = 'MEDIA_FILE_REMOVE_SUCCESS';
export const MEDIA_FILE_REMOVE_FAILURE = 'MEDIA_FILE_REMOVE_FAILURE';

// ==============================================================================================
// TAG ==========================================================================================
// ==============================================================================================
export const TAG_LIST_FETCH_SUCCESS = 'TAG_LIST_FETCH_SUCCESS';
export const TAG_LIST_FETCH_FAILURE = 'TAG_LIST_FETCH_FAILURE';

// ==============================================================================================
// ADVERTISING CAMPAIGN =========================================================================
// ==============================================================================================

export const ADVERTISING_CAMPAIGN_LIST_FETCH_SUCCESS = 'ADVERTISING_CAMPAIGN_LIST_FETCH_SUCCESS';
export const ADVERTISING_CAMPAIGN_LIST_FETCH_FAILURE = 'ADVERTISING_CAMPAIGN_LIST_FETCH_FAILURE';

export const ADVERTISING_CAMPAIGN_FETCH_SUCCESS = 'ADVERTISING_CAMPAIGN_FETCH_SUCCESS';
export const ADVERTISING_CAMPAIGN_FETCH_FAILURE = 'ADVERTISING_CAMPAIGN_FETCH_FAILURE';
export const ADVERTISING_CAMPAIGN_UPDATE_SUCCESS = 'ADVERTISING_CAMPAIGN_UPDATE_SUCCESS';
export const ADVERTISING_CAMPAIGN_UPDATE_FAILURE = 'ADVERTISING_CAMPAIGN_UPDATE_FAILURE';
export const ADVERTISING_CAMPAIGN_CREATE_SUCCESS = 'ADVERTISING_CAMPAIGN_CREATE_SUCCESS';
export const ADVERTISING_CAMPAIGN_CREATE_FAILURE = 'ADVERTISING_CAMPAIGN_CREATE_FAILURE';
export const ADVERTISING_CAMPAIGN_REMOVE_SUCCESS = 'ADVERTISING_CAMPAIGN_REMOVE_SUCCESS';
export const ADVERTISING_CAMPAIGN_REMOVE_FAILURE = 'ADVERTISING_CAMPAIGN_REMOVE_FAILURE';
import React from "react";
import moment from "moment";
import {connect} from "react-redux";
import PropTypes from "prop-types"
import {I18n} from "../utils/i18n";
import {Pagination, TagTreeCard} from '../containers'
import Validator from "../utils/validator";
import Error from "../utils/error";
import * as api from "../api";
import {parseTime, Resource} from "../utils/helpers";
import {Preloader} from "../components";
import {DatePicker, TimePicker} from 'antd';

import "antd/dist/antd.min.css";

const dateFormat = 'DD-MM-YYYY';
const timeFormat = 'HH:mm';

const list_to_tree = (list) => {
  let map = {}, node, roots = [], i;

  let items = list.map(item => {
    return {
      title: item.name,
      key: item.id,
      parentId: item.parent_id,
    }
  });

  for (i = 0; i < items.length; i++) {
    map[items[i].key] = i; // initialize the map
    items[i].children = []; // initialize the children
  }

  for (i = 0; i < items.length; i++) {
    node = items[i];
    if (node.parentId !== null) {
      node.checkable = true
      node.selectable = true
      items[map[node.parentId]].children.push(node);
    } else {
      roots.push(node)
    }
  }

  for (i = 0; i < roots.length; i++) {
    roots[i].checkable = !roots[i].children;
    roots[i].selectable = !roots[i].children;
  }

  return roots;
}

class AdvertisingCampaignFormUpdate extends React.Component {
  constructor(props) {
    super(props);

    const data = props.data || {meta: {}, spec: {}};
    const file = (!!data.spec && !!data.spec.file) ? {id: data.spec.file.id, name: data.spec.file.name} : null;

    let tags = [];
    (data.spec.tags || []).forEach(item => {
      return tags.push(item.id)
    });

    this.state = {
      media: {
        items: [],
        total: 0,
        page: 1,
        limit: 10,
        pages: 1,
        maxPages: 5,
      },
      data: {
        name: data.meta.name || "",
        file: file || null,
        tags: tags || [],
        start: moment(data.spec.start, "YYYY-MM-DD").toDate(),
        end: moment(data.spec.end, "YYYY-MM-DD").toDate(),
        start_time: parseTime(data.spec.start_time || "00:00:00"),
        end_time: parseTime(data.spec.end_time || "23:59:59"),
        allDay: !data.spec.use_time,
      },
      error: {
        name: "",
        date: "",
        range_start_date: "",
        range_end_date: "",
        form: ""
      },
      tags: [],
      waiting: true,
      pending: false
    };
    this.ranges = {};
    this.ranges[I18n.t('key_AdvertisingCampaignFormUpdate_FormDate_Today')] = [moment().startOf('day'), moment().endOf('day')];
    this.ranges[I18n.t('key_AdvertisingCampaignFormUpdate_FormDate_ThisMonth')] = [moment().startOf('month'), moment().endOf('month')];

    this.handleOnChange()
  }

  componentDidMount() {
    api.Tag.List()
      .then((res) => {
        let tmp = res.map(item => item.id)
        let tags = this.state.data.tags.filter(item => tmp.indexOf(item) > -1);
        this.setState({
          waiting: false,
          tags: list_to_tree(res),
          data: {...this.state.data, tags: tags}
        }, this.handleOnChange)
      });

    api.Media.FileList({
      page: this.state.media.page,
      limit: this.state.media.limit
    })
      .then((res) => {

        const pages = (res.total > this.state.media.limit) ? Math.ceil(res.total / this.state.media.limit) : 1;

        if (res.page > pages) return this.handleMediaFilePageChange(pages);

        this.setState({
          media: {
            ...this.state.media,
            total: res.total,
            page: res.page,
            pages: pages,
            items: (res.items || []).filter(this.fileFilter)
          }
        })
      })
      .catch(() => {
      });
  }

  handleMediaFilePageChange = (page) => {
    api.Media.FileList({
      page: page,
      limit: this.state.media.limit
    })
      .then((res) => {

        const pages = (res.total > this.state.media.limit) ? Math.ceil(res.total / this.state.media.limit) : 1;

        if (res.page > pages) return this.handleMediaFilePageChange(pages);

        this.setState({
          media: {
            ...this.state.media,
            total: res.total,
            page: res.page,
            pages: pages,
            items: (res.items || []).filter(this.fileFilter)
          }
        })
      })
      .catch(() => {
      });
  };

  fileFilter = (item) => item.status.state === "ready";

  handleSelectFile = (e, item) => {
    this.setState({data: {...this.state.data, file: {id: item.meta.id, name: item.meta.name}}}, this.handleOnChange)
  };

  handleUnselectFile = (e) => {
    this.setState({data: {...this.state.data, file: null}}, this.handleOnChange)
  };

  handleUseTime = (e) => {
    const checked = e.target.checked
    this.setState(state => {
      state.data.allDay = checked;
      if (checked) {
        state.data.start_time = parseTime("00:00:00");
        state.data.end_time = parseTime("23:59:59");
      }
      return state;
    }, this.handleOnChange)
  }

  handleDateChange = (dates) => {
    this.setState(state => {
      const start = dates[0].toDate();
      const end = dates[1].toDate();
      state.data.start = new Date(start.getFullYear(), start.getMonth(), start.getDate(), 0, 0, 0, 0);
      state.data.end = new Date(end.getFullYear(), end.getMonth(), end.getDate(), 23, 59, 59, 0);
      return state
    }, this.handleOnChange)
  }

  handleTimeChange = (name, value) => {
    let date = !!value ? new Date(value.utc().toDate()) : null;
    this.setState(state => {
      state.data[name] = date
      return state
    }, this.handleOnChange)
  };

  handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    let value = target.type === 'checkbox' ? target.checked : target.value;

    let error = this.state.error;
    Object.keys(error).forEach((name) => error[name] = "");

    let patch = {};
    switch (name) {
      case "tags":
        patch = {data: {...this.state.data, [name]: value}};
        break;
      case "name":

        if (name === "name") {
          if (!!Validator.NotEmptyField(value)) {
            error["name"] = Error.FIELD_CANNOT_BE_BLANK();
          }
        }

        patch = {data: {...this.state.data, [name]: value}};
        break;
      default:
    }

    this.setState({
      ...patch,
      error: error,
      waiting: false,
      success: false
    }, this.handleOnChange)
  };

  handleOnChange = () => {
    let data = {...this.state.data};
    let event = new Event(this.props.name);

    data.use_time = !data.allDay;

    Object.defineProperty(event, 'target', {
      writable: false,
      value: {name: this.props.name, value: data},
    });

    this.props.onChange(event)
  };

  disabledDate = (current) => {
    // Can not select days before today and today
    return current && current.valueOf() < moment().subtract(1, "days");
  }

  render() {

    if (this.state.waiting) return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <Preloader/>
      </div>
    );

    return (
      <div className="row">

        <div className="col-6">

          {/* ================================================================================= */}
          {/* NAME ============================================================================ */}
          {/* ================================================================================= */}

          <div className="form-group">
            <label htmlFor="nameInput"
                   className="col-form-label">{I18n.t('key_AdvertisingCampaignFormUpdate_LabelName')}</label>
            <input type="text"
                   className={`form-control ${this.state.error.name ? 'is-invalid' : ''}`}
                   id="nameInput"
                   placeholder={I18n.t('key_AdvertisingCampaignFormUpdate_PlaceholderName')}
                   name="name"
                   onChange={this.handleChange}
                   disabled={this.props.disabled}
                   value={this.state.data.name}/>
            <div className="invalid-feedback">{this.state.error.name}</div>
          </div>

          {/* ================================================================================= */}
          {/* DATES =========================================================================== */}
          {/* ================================================================================= */}
          <div>

            <div className="mt-4">
              <div className="input-group">
                <div className="input-group-prepend">
                  <label className="input-group-text" htmlFor="date_filter" style={{width: "80px"}}>
                    {I18n.t('key_AdvertisingCampaignFormUpdate_LabelDateOrDates')}
                  </label>
                </div>
                <DatePicker.RangePicker id="date_filter"
                                        className="mr-sm-2"
                                        placeholder={[I18n.t('key_AdvertisingCampaignFormUpdate_LabelDateStart'), I18n.t('key_AdvertisingCampaignFormUpdate_LabelDateEnd')]}
                                        ranges={this.ranges}
                                        defaultValue={[moment(this.state.data.start, dateFormat), moment(this.state.data.end, dateFormat)]}
                                        value={[moment(this.state.data.start, timeFormat), moment(this.state.data.end, timeFormat)]}
                                        format={dateFormat}
                                        showTime={false}
                                        disabledDate={this.disabledDate}
                                        onChange={this.handleDateChange}
                                        disabled={this.props.disabled}
                                        allowClear={false}
                                        showToday={false}
                                        showOk={true}
                                        allowEmpty={false}
                />
                {!!this.state.error.range_start_date &&
                  <div className="invalid-feedback"
                       style={{display: "block"}}>{this.state.error.range_start_date}</div>}
                {!!this.state.error.range_end_date &&
                  <div className="invalid-feedback"
                       style={{display: "block"}}>{this.state.error.range_end_date}</div>}
              </div>
            </div>

            <div className="mt-4">
              <div className="input-group">
                <div className="input-group-prepend">
                  <label className="input-group-text" htmlFor="date_filter" style={{width: "80px"}}>
                    {I18n.t('key_AdvertisingCampaignFormUpdate_LabelTime')}
                  </label>
                </div>
                <TimePicker format={timeFormat}
                            inputReadOnly={true}
                            allowClear={false}
                            defaultValue={moment('00:00', timeFormat)}
                            value={moment(this.state.data.start_time)}
                            onChange={val => this.handleTimeChange("start_time", val)}
                            disabled={this.state.data.allDay || this.props.disabled}
                            showNow={false}
                />

                <TimePicker format={timeFormat}
                            inputReadOnly={true}
                            allowClear={false}
                            defaultValue={moment('23:59:59', timeFormat)}
                            value={moment(this.state.data.end_time)}
                            onChange={val => this.handleTimeChange("end_time", val)}
                            disabled={this.state.data.allDay || this.props.disabled}
                            showNow={false}
                />
              </div>

              <div className="form-check">
                <input className="form-check-input" type="checkbox"
                       defaultChecked={this.state.data.allDay}
                       value={this.state.data.allDay}
                       onClick={this.handleUseTime}
                       disabled={this.props.disabled}
                       id="flexCheckDefault"/>
                <label className="form-check-label"
                       htmlFor="flexCheckDefault"
                       style={{marginTop: "1px"}}>
                  Проигрывать в течение дня
                </label>
              </div>
            </div>

          </div>

          {/* ================================================================================= */}
          {/* MEDIA FILE ====================================================================== */}
          {/* ================================================================================= */}
          <div className="mt-4">
            <label htmlFor="nameInput"
                   className="col-form-label">{I18n.t('key_AdvertisingCampaignFormUpdate_MediaFileTitle')}</label>
            {
              (!!this.state.data.file)
                ? (
                  <div>
                    {this.state.data.file.name}&nbsp;{!this.props.disabled &&
                    <i className="fa fa-times text-danger cursor-pointer"
                       onClick={this.handleUnselectFile}/>}
                  </div>
                )
                : (
                  <div>
                    <div className="list-group list-group-flush">

                      {
                        (this.state.media.items).map((item, index) => {
                          return (
                            <div key={index}
                                 className="list-group-item d-flex justify-content-between align-items-center cursor-pointer"
                                 onClick={e => this.handleSelectFile(e, item)}>

                              <div style={{verticalAlign: "middle", maxWidth: "300px"}}
                                   className="text-truncate">{item.meta.name}</div>

                              <div style={{verticalAlign: "middle"}}>
                                <span className="badge badge-info" style={{width: "60px"}}>
                                  {Resource.MemoryBytesToHumanSize(item.meta.size, 2)}
                                </span>
                              </div>

                            </div>
                          )
                        })
                      }

                    </div>

                    <div className="mt-4">
                      <Pagination maxPages={this.state.media.maxPages}
                                  currentPage={this.state.media.page}
                                  numPages={this.state.media.pages}
                                  change={this.handleMediaFilePageChange}/>
                    </div>

                  </div>
                )
            }
          </div>

        </div>

        <div className="col-6">
          {/* ================================================================================= */}
          {/* TAGS ============================================================================ */}
          {/* ================================================================================= */}
          <div className="form-group mb-4">
            <label htmlFor="nameInput"
                   className="col-form-label">{I18n.t('key_AdvertisingCampaignFormUpdate_TagsTitle')}</label>


            {
              (this.props.data.status.status === "created")
                ? (
                  (!this.state.tags.length)
                    ? <div>{I18n.t('key_AdvertisingCampaignFormCreate_ThereAreNoTagsAvailable')}</div>
                    : <TagTreeCard name="tags"
                                   data={this.state.tags}
                                   selected={this.state.data.tags}
                                   disabled={this.props.disabled}
                                   onChange={this.handleChange}/>
                )
                : (
                  <div>
                    {
                      (this.props.data.spec.tags || []).map((item, index) => {
                        return <div key={index} className="badge badge-primary mr-2">{item.name}</div>
                      })
                    }
                  </div>
                )
            }
          </div>

        </div>

      </div>
    );
  }
}

AdvertisingCampaignFormUpdate.defaultProps = {
  data: {},
};

AdvertisingCampaignFormUpdate.propTypes = {
  name: PropTypes.string,
  data: PropTypes.object,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => props;

export default connect(mapStateToProps)(AdvertisingCampaignFormUpdate);

import React from "react";
import {NavLink} from "react-router-dom";
import {I18n} from "../utils/i18n";
import {AccountFormPasswordChange, AccountFormProfileInfo, AccountFormOrganizationInfo} from "../containers";
import {Account, Session} from "../actions";
import {getAccount} from "../selectors";
import {connect} from "react-redux";

class PageAccountSettings extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      changePasswordFormShow: false
    }
  }

  handleProfileUpdate = (data) => {
    return this.props.dispatch(Account.Profile.Update({
      email: data.email,
      first_name: data.first_name,
      last_name: data.last_name,
      phone: data.phone,
    }))
  };

  handlePasswordUpdate = (data) => {
    return this.props.dispatch(Account.Password.Update(data))
      .then((res) => {
        this.props.dispatch(Session.Set((!!res && !!res.token) ? res.token : null))
        this.setState({changePasswordFormShow: false})
      })
  };

  handlerPasswordFormShow = () => {
    this.setState({changePasswordFormShow: true})
  }

  handleInfoUpdate = () => {

  }

  render() {

    const {account} = this.props;

    return (
      <section className="container content">

        <div className="subnav-panel">
          <div className="d-flex">
            <div>
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><NavLink to={"/"}>{I18n.t('key_Home')}</NavLink></li>
                <li className="breadcrumb-item active">{account.email}</li>
              </ol>
            </div>
          </div>
        </div>

        <div className="row mb-5">
          <div className="col-md-4">
            <h4>{I18n.t('key_PageAccountSettings_ProfileInfo')}</h4>
            <AccountFormProfileInfo account={account} submit={this.handleProfileUpdate}/>
          </div>
        </div>

        <div className="row mb-5">
          <div className="col-md-8">
            <h4>{I18n.t('key_PageAccountSettings_Password')}</h4>
            {
              this.state.changePasswordFormShow
                ? <AccountFormPasswordChange submit={this.handlePasswordUpdate}/>
                : <button className="btn btn-primary"
                          onClick={this.handlerPasswordFormShow}>{I18n.t('key_Change')}</button>
            }

          </div>
        </div>

        <div className="row mb-5">
          <div className="col-md-8">
            <h4>{I18n.t('key_PageAccountSettings_OrganizationInfo')}</h4>
            <AccountFormOrganizationInfo account={account} disabled={true}/>
          </div>
        </div>

      </section>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    account: getAccount(state),
  }
};

export default connect(mapStateToProps)(PageAccountSettings);
import React from "react";
import moment from "moment";
import {connect} from "react-redux";
import PropTypes from "prop-types"
import {I18n} from "../utils/i18n";
import {Button, Pagination, TagTreeCard} from '../containers'
import {Preloader} from '../components'
import Validator from "../utils/validator";
import Error from "../utils/error";
import {parseTime, formatDate, Resource} from "../utils/helpers";
import * as api from "../api";
import {DatePicker, TimePicker} from 'antd';

import "antd/dist/antd.min.css";

const dateFormat = 'DD-MM-YYYY';
const timeFormat = 'HH:mm';

const list_to_tree = (list) => {
  let map = {}, node, roots = [], i;

  let items = list.map(item => {
    return {
      title: item.name,
      key: item.id,
      parentId: item.parent_id,
    }
  });

  for (i = 0; i < items.length; i++) {
    map[items[i].key] = i; // initialize the map
    items[i].children = []; // initialize the children
  }

  for (i = 0; i < items.length; i++) {
    node = items[i];
    if (node.parentId !== null) {
      node.checkable = true
      node.selectable = true
      items[map[node.parentId]].children.push(node);
    } else {
      roots.push(node)
    }
  }

  for (i = 0; i < roots.length; i++) {
    roots[i].checkable = !roots[i].children;
    roots[i].selectable = !roots[i].children;
  }

  return roots;
}

class AdvertisingCampaignFormCreate extends React.Component {
  constructor(props) {
    super(props);

    let now = new Date();

    this.state = {
      media: {
        items: [],
        total: 0,
        page: 1,
        limit: 10,
        pages: 1,
        maxPages: 5,
      },
      data: {
        name: "",
        file: null,
        tags: [],
        start: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0),
        end: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 0),
        start_time: parseTime("00:00:00"),
        end_time: parseTime("23:59:59"),
        allDay: true,
      },
      error: {
        name: "",
        range_start_date: "",
        range_end_date: "",
        form: ""
      },
      tags: [],
      pending: true
    };

    this.ranges = {};
    this.ranges[I18n.t('key_AdvertisingCampaignFormUpdate_FormDate_Today')] = [moment().startOf('day'), moment().endOf('day')];
    this.ranges[I18n.t('key_AdvertisingCampaignFormUpdate_FormDate_ThisMonth')] = [moment().startOf('month'), moment().endOf('month')];
  }

  componentDidMount() {
    api.Tag.List()
      .then((res) => {
        this.setState({pending: false, tags: list_to_tree(res)})
      });

    api.Media.FileList({
      page: this.state.media.page,
      limit: this.state.media.limit
    })
      .then((res) => {

        const pages = (res.total > this.state.media.limit) ? Math.ceil(res.total / this.state.media.limit) : 1;

        if (res.page > pages) return this.handleMediaFilePageChange(pages);

        this.setState({
          media: {
            ...this.state.media,
            total: res.total,
            page: res.page,
            pages: pages,
            items: (res.items || []).filter(this.fileFilter)
          }
        })
      })
  }

  fileFilter = (item) => item.status.state === "ready";

  handleMediaFilePageChange = (page) => {
    api.Media.FileList({
      page: page,
      limit: this.state.media.limit
    })
      .then((res) => {
        const pages = (res.total > this.state.media.limit) ? Math.ceil(res.total / this.state.media.limit) : 1;

        if (res.page > pages) return this.handleMediaFilePageChange(pages);

        this.setState({
          media: {
            ...this.state.media,
            total: res.total,
            page: res.page,
            pages: pages,
            items: (res.items || []).filter(this.fileFilter)
          }
        })
      })
      .catch(() => {
      });
  };

  handleSelectFile = (e, item) => {
    this.setState({data: {...this.state.data, file: {id: item.meta.id, name: item.meta.name}}})
  };

  handleUnselectFile = () => {
    this.setState({data: {...this.state.data, file: null}})
  };

  handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    let value = target.type === 'checkbox' ? target.checked : target.value;

    let error = this.state.error;
    Object.keys(error).forEach((name) => error[name] = "");

    let patch = {};
    switch (name) {
      case "tags":
        patch = {data: {...this.state.data, [name]: value}};
        break;
      case "name":

        if (name === "name") {
          if (!!Validator.NotEmptyField(value)) {
            error["name"] = Error.FIELD_CANNOT_BE_BLANK();
          }
        }

        patch = {data: {...this.state.data, [name]: value}};
        break;
      default:
    }

    this.setState({
      ...patch,
      error: error,
      pending: false,
      success: false
    })
  };

  handleUseTime = (e) => {
    const checked = e.target.checked
    this.setState(state => {
      state.data.allDay = checked;
      if (checked) {
        state.data.start_time = parseTime("00:00:00");
        state.data.end_time = parseTime("23:59:59");
      }
      return state;
    })
  }

  handleDateChange = (dates) => {
    this.setState(state => {
      const start = dates[0].toDate();
      const end = dates[1].toDate();
      state.data.start = new Date(start.getFullYear(), start.getMonth(), start.getDate(), 0, 0, 0, 0);
      state.data.end = new Date(end.getFullYear(), end.getMonth(), end.getDate(), 23, 59, 59, 0);
      return state
    })
  }

  handleTimeChange = (name, value) => {
    let date = !!value ? new Date(value.utc().toDate()) : null;
    this.setState(state => {
      state.data[name] = date
      return state
    })
  };

  handleCancel = (e) => {
    this.props.cancel()
  };

  handleSubmit = (e) => {
    e.preventDefault();

    let data = {...this.state.data};

    let spec = {};
    spec.name = data.name;
    spec.file_id = data.file.id;
    spec.tags = data.tags;
    spec.use_time = !data.allDay;
    spec.start = formatDate(data.start);
    spec.end = formatDate(data.end);

    spec.start_time = data.start_time.toLocaleTimeString('ru-RU', {
      hour12: false,
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    });
    spec.end_time = data.end_time.toLocaleTimeString('ru-RU', {
      hour12: false,
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    });

    return Promise.resolve(this.props.submit(spec))
      .then(this.handleSuccess, this.handleError)
  };

  handleSuccess = () => {

  };

  handleError = (errors) => {
    let e = this.state.error;
    if (errors.status === "Bad Request") e.form = errors.message;
    this.setState({error: e, pending: false});
  };

  handleDisabled = () => {
    return (
      this.state.pending
      || !this.state.data.name
      || !this.state.data.file
      || !this.state.data.tags.length
      || (!this.state.data.start || !this.state.data.end
        || !(!!this.state.data.start && !!this.state.data.end && this.state.data.start.getTime() <= this.state.data.end.getTime()))
    )
  };

  disabledDate = (current) => {
    // Can not select days before today and today
    return current && current.valueOf() < moment().subtract(1, "days");
  }

  render() {
    if (this.state.pending) return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <Preloader/>
      </div>
    );

    return (
      <div className="row">

        <div className="col-6">

          {/* ================================================================================= */}
          {/* NAME ============================================================================ */}
          {/* ================================================================================= */}

          <div className="form-group mb-2">
            <label htmlFor="nameInput"
                   className="col-form-label">{I18n.t('key_AdvertisingCampaignFormCreate_LabelName')}</label>
            <input type="text"
                   className={`form-control ${this.state.error.name ? 'is-invalid' : ''}`}
                   id="nameInput"
                   placeholder={I18n.t('key_AdvertisingCampaignFormCreate_PlaceholderName')}
                   name="name"
                   onChange={this.handleChange}
                   value={this.state.data.name}/>
            <div className="invalid-feedback">{this.state.error.name}</div>
          </div>

          {/* ================================================================================= */}
          {/* DATES =========================================================================== */}
          {/* ================================================================================= */}

          <div className="input-group mt-4">
            <div className="input-group-prepend">
              <label className="input-group-text" htmlFor="date_filter" style={{width: "80px"}}>
                {I18n.t('key_AdvertisingCampaignFormUpdate_LabelDateOrDates')}
              </label>
            </div>
            <DatePicker.RangePicker id="date_filter"
                                    className="mr-sm-2"
                                    placeholder={[I18n.t('key_AdvertisingCampaignFormUpdate_LabelDateStart'), I18n.t('key_AdvertisingCampaignFormUpdate_LabelDateEnd')]}
                                    ranges={this.ranges}
                                    defaultValue={[moment(this.state.data.start, dateFormat), moment(this.state.data.end, dateFormat)]}
                                    format={dateFormat}
                                    showTime={false}
                                    disabledDate={this.disabledDate}
                                    onChange={this.handleDateChange}
                                    allowClear={false}
                                    showToday={false}
                                    showOk={true}
                                    allowEmpty={false}
            />
            {!!this.state.error.range_start_date &&
              <div className="invalid-feedback"
                   style={{display: "block"}}>{this.state.error.range_start_date}</div>}
            {!!this.state.error.range_end_date &&
              <div className="invalid-feedback"
                   style={{display: "block"}}>{this.state.error.range_end_date}</div>}
          </div>

          <div className="input-group mt-4">
            <div className="input-group-prepend">
              <label className="input-group-text" htmlFor="date_filter" style={{width: "80px"}}>
                {I18n.t('key_AdvertisingCampaignFormUpdate_LabelTime')}
              </label>
            </div>
            <TimePicker format={timeFormat}
                        inputReadOnly={true}
                        allowClear={false}
                        defaultValue={moment('00:00', timeFormat)}
                        value={moment(this.state.data.start_time)}
                        onChange={val => this.handleTimeChange("start_time", val)}
                        disabled={this.state.data.allDay}
            />

            <TimePicker format={timeFormat}
                        inputReadOnly={true}
                        allowClear={false}
                        defaultValue={moment('23:59:59', timeFormat)}
                        value={moment(this.state.data.end_time)}
                        onChange={val => this.handleTimeChange("end_time", val)}
                        disabled={this.state.data.allDay}
            />
          </div>

          <div className="form-check">
            <input className="form-check-input" type="checkbox"
                   defaultChecked={this.state.data.allDay}
                   value={this.state.data.allDay}
                   onClick={this.handleUseTime}
                   id="flexCheckDefault"/>
            <label className="form-check-label"
                   htmlFor="flexCheckDefault"
                   style={{marginTop: "1px"}}>
              Проигрывать в течение дня
            </label>
          </div>


          {/* ================================================================================= */}
          {/* MEDIA FILE ====================================================================== */}
          {/* ================================================================================= */}
          <div className="mt-4">

            <label htmlFor="nameInput"
                   className="col-form-label">{I18n.t('key_AdvertisingCampaignFormUpdate_MediaFileTitle')}</label>

            <div>
              {
                (!!this.state.data.file)
                  ? (
                    <div>
                      {this.state.data.file.name}&nbsp;<i className="fa fa-times text-danger cursor-pointer"
                                                          onClick={this.handleUnselectFile}/>
                    </div>
                  )
                  : (
                    <div>
                      <div className="list-group list-group-flush">

                        {
                          (this.state.media.items).map((item, index) => {
                            return (
                              <div key={index}
                                   className="list-group-item d-flex justify-content-between align-items-center cursor-pointer"
                                   onClick={e => this.handleSelectFile(e, item)}>

                                <div style={{verticalAlign: "middle", maxWidth: "300px"}}
                                     className="text-truncate">{item.meta.name}</div>

                                <div style={{verticalAlign: "middle"}}>
                                <span className="badge badge-info" style={{width: "60px"}}>
                                  {Resource.MemoryBytesToHumanSize(item.meta.size, 2)}
                                </span>
                                </div>

                              </div>
                            )
                          })
                        }

                      </div>

                      <div className="mt-4">
                        <Pagination maxPages={this.state.media.maxPages}
                                    currentPage={this.state.media.page}
                                    numPages={this.state.media.pages}
                                    change={this.handleMediaFilePageChange}/>
                      </div>

                    </div>
                  )
              }
            </div>
          </div>

        </div>

        <div className="col-6">

          {/* ================================================================================= */}
          {/* TAGS ============================================================================ */}
          {/* ================================================================================= */}
          <div className="form-group mb-5">
            <label htmlFor="nameInput"
                   className="col-form-label">{I18n.t('key_AdvertisingCampaignFormCreate_TagsTitle')}</label>

            {
              (!this.state.tags.length)
                ? (
                  <div>{I18n.t('key_AdvertisingCampaignFormCreate_ThereAreNoTagsAvailable')}</div>
                )
                : (
                  <TagTreeCard name="tags"
                               data={this.state.tags}
                               disableExpand={true}
                               onChange={this.handleChange}/>
                )
            }
          </div>

        </div>


        <div className="pt-4 d-flex justify-content-between">
          <div className="col-6 pl-0 text-left">
            <button className="btn btn-inverse"
                    onClick={this.handleCancel}
                    type="button">
              {I18n.t('key_Cancel')}
            </button>
          </div>

          <div className="col-6 pr-0 text-right">
            <Button type='submit'
                    className='btn btn-primary text-capitalize'
                    disabled={this.handleDisabled()}
                    submit={this.handleSubmit}
                    main={I18n.t('key_Create')}
            />
          </div>
        </div>

      </div>
    );
  }
}

AdvertisingCampaignFormCreate.defaultProps = {};

AdvertisingCampaignFormCreate.propTypes = {
  submit: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => props;

export default connect(mapStateToProps)(AdvertisingCampaignFormCreate);

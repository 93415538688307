import React from "react"
import PropTypes from "prop-types";
import {I18n} from "../utils/i18n";
import {BankSuggestionsByBic, Button, OrganizationSuggestionsByInn} from "../containers";

const dadata_key = window._env_.DADATA_KEY;

class AccountFormOrganizationInfo extends React.Component {

  constructor(props) {
    super(props);
    const data = props.account || {organization: {}};
    this.state = {
      data: {
        itn: data.organization.itn || "", // ИНН
        iec: data.organization.iec || "", // КПП
        psrn: data.organization.psrn || "", // ОГРН
        bic: data.organization.bic || "", // БИK
        name: data.organization.name || "", // Название организации
        actual_address: data.organization.actual_address || "", // Фактический адрес
        legal_address: data.organization.legal_address || "", // Юридический адрес
        ceo: data.organization.ceo || "", // Гениральный директор
        bank: data.organization.bank || "", // Название банка
        current_account: data.organization.current_account || "", // Расчетный свет
        corresponding_account: data.organization.corresponding_account || "", // Корреспондирующий счёт
      },
      error: {
        itn: "",
        iec: "",
        psrn: "",
        bic: "",
        name: "",
        actual_address: "",
        legal_address: "",
        ceo: "",
        bank: "",
        current_account: "",
        corresponding_account: "",
      },
      pending: false,
    };
  }

  handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    let value = target.type === 'checkbox' ? target.checked : target.value;

    let patch = {};
    switch (name) {
      case "iec":
      case "psrn":
      case "name":
      case "ceo":
      case "bank":
      case "legal_address":
      case "actual_address":
      case "current_account":
      case "corresponding_account":
        patch = {data: {...this.state.data, [name]: value}};
        break;
      case  "bic":
        patch = {
          data: {
            ...this.state.data,
            bank: value.name || "",
            bic: value.bic || "",
            corresponding_account: value.correspondent_account || "",
          }
        };
        break;
      case "itn":
        patch = {
          data: {
            ...this.state.data,
            itn: value.itn || "",
            iec: value.iec || "",
            psrn: value.psrn || "",
            legal_address: value.legal_address || "",
            name: value.name || "",
            ceo: value.ceo || "",
          }
        };
        break;
      default:
    }

    this.setState(patch)
  }

  handleEvent = () => {
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let data = {...this.state.data};

    return Promise.resolve(this.props.submit(data))
      .then(this.handleSuccess, this.handleError)
  };

  handleSuccess = (res) => {
    this.setState({pending: false});
  };

  handleError = (errors) => {
    let e = this.state.error;
    if (errors.status === "Bad Request") e.form = errors.message;
    if (errors.message === "Bad itn parameter") e.itn = I18n.t('key_errorMsg_BadItnParameter');
    if (errors.message === "Bad iec parameter") e.iec = I18n.t('key_errorMsg_BadIecParameter');
    if (errors.message === "Bad psrn parameter") e.psrn = I18n.t('key_errorMsg_BadPsrnParameter');
    if (errors.message === "Bad bic parameter") e.bic = I18n.t('key_errorMsg_BadBicParameter');
    if (errors.message === "Bad name parameter") e.name = I18n.t('key_errorMsg_BadNameParameter');
    if (errors.message === "Bad actual_address parameter") e.actual_address = I18n.t('key_errorMsg_BadActualAddressParameter');
    if (errors.message === "Bad legal_address parameter") e.legal_address = I18n.t('key_errorMsg_BadLegalAddressParameter');
    if (errors.message === "Bad ceo parameter") e.ceo = I18n.t('key_errorMsg_BadCeoParameter');
    if (errors.message === "Bad bank parameter") e.bank = I18n.t('key_errorMsg_BadBankParameter');
    if (errors.message === "Bad current_account parameter") e.current_account = I18n.t('key_errorMsg_BadCurrentAccountParameter');
    if (errors.message === "Bad corresponding_account parameter") e.corresponding_account = I18n.t('key_errorMsg_BadCorrespondingAccountParameter');

    this.setState({error: e, pending: false});
  };

  handleDisabled = () => {
    return (
      this.state.pending ||
      this.state.data.itn === "" ||
      this.state.data.iec === "" ||
      this.state.data.psrn === "" ||
      this.state.data.bic === "" ||
      this.state.data.name === "" ||
      this.state.data.actual_address === "" ||
      this.state.data.legal_address === "" ||
      this.state.data.ceo === "" ||
      this.state.data.bank === "" ||
      this.state.data.current_account === "" ||
      this.state.data.corresponding_account === ""
    )
  };

  render() {
    return (
      <div>

        <div className="row">
          <div className="col form-group">
            <label htmlFor="itn">{I18n.t('key_AccountFormOrganizationInfo_ITNLabel')}</label>
            <OrganizationSuggestionsByInn id="itn" name="itn"
                                          disabled={this.props.disabled}
                                          className={`form-control form-control-sm ${this.state.error.itn ? 'is-invalid' : ''}`}
                                          token={dadata_key}
                                          value={this.state.data.itn}
                                          onChange={this.handleChange}/>
            <div className="invalid-feedback">{this.state.error.itn}</div>
          </div>
          <div className="col form-group">
            <label htmlFor="bank">{I18n.t('key_AccountFormOrganizationInfo_BankLabel')}</label>
            <input className={`form-control form-control-sm ${this.state.error.bank ? 'is-invalid' : ''}`} type="text"
                   disabled={this.props.disabled}
                   id="bank" name="bank"
                   onChange={this.handleChange}
                   value={this.state.data.bank}/>
            <div className="invalid-feedback">{this.state.error.bank}</div>
          </div>
        </div>

        <div className="row">
          <div className="col form-group">
            <label htmlFor="iec">{I18n.t('key_AccountFormOrganizationInfo_IECLabel')}</label>
            <input className={`form-control form-control-sm ${this.state.error.iec ? 'is-invalid' : ''}`} type="text"
                   disabled={this.props.disabled}
                   id="iec" name="iec"
                   onChange={this.handleChange}
                   value={this.state.data.iec}/>
            <div className="invalid-feedback">{this.state.error.iec}</div>
          </div>
          <div className="col form-group">
            <label htmlFor="current_account">{I18n.t('key_AccountFormOrganizationInfo_CurrentAccountLabel')}</label>
            <input className={`form-control form-control-sm ${this.state.error.current_account ? 'is-invalid' : ''}`}
                   disabled={this.props.disabled}
                   type="text" id="current_account" name="current_account"
                   onChange={this.handleChange}
                   value={this.state.data.current_account}/>
            <div className="invalid-feedback">{this.state.error.current_account}</div>
          </div>
        </div>

        <div className="row">
          <div className="col form-group">
            <label htmlFor="psrn">{I18n.t('key_AccountFormOrganizationInfo_PSRNLabel')}</label>
            <input className={`form-control form-control-sm ${this.state.error.psrn ? 'is-invalid' : ''}`} type="text"
                   disabled={this.props.disabled}
                   id="psrn" name="psrn"
                   onChange={this.handleChange}
                   value={this.state.data.psrn}/>
            <div className="invalid-feedback">{this.state.error.psrn}</div>
          </div>
          <div className="col form-group">
            <label
              htmlFor="corresponding_account">{I18n.t('key_AccountFormOrganizationInfo_CorrespondingAccountLabel')}</label>
            <input
              disabled={this.props.disabled}
              className={`form-control form-control-sm ${this.state.error.corresponding_account ? 'is-invalid' : ''}`}
              type="text" id="corresponding_account"
              name="corresponding_account"
              onChange={this.handleChange}
              value={this.state.data.corresponding_account}/>
            <div className="invalid-feedback">{this.state.error.corresponding_account}</div>
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="bic">{I18n.t('key_AccountFormOrganizationInfo_BICLabel')}</label>
          <BankSuggestionsByBic id="bic" name="bic"
                                disabled={this.props.disabled}
                                className={`form-control form-control-sm ${this.state.error.bic ? 'is-invalid' : ''}`}
                                token={dadata_key}
                                value={this.state.data.bic}
                                onChange={this.handleChange}/>
          <div className="invalid-feedback">{this.state.error.bic}</div>
        </div>

        <div className="form-group">
          <label htmlFor="name">{I18n.t('key_AccountFormOrganizationInfo_NameLabel')}</label>
          <input className={`form-control form-control-sm ${this.state.error.name ? 'is-invalid' : ''}`} type="text"
                 disabled={this.props.disabled}
                 id="name" name="name"
                 onChange={this.handleChange}
                 value={this.state.data.name}/>
          <div className="invalid-feedback">{this.state.error.name}</div>
        </div>

        <div className="form-group">
          <label htmlFor="actual_address">{I18n.t('key_AccountFormOrganizationInfo_ActualAddressLabel')}</label>
          <input className={`form-control form-control-sm ${this.state.error.actual_address ? 'is-invalid' : ''}`} type="text"
                 disabled={this.props.disabled}
                 id="actual_address" name="actual_address"
                 onChange={this.handleChange}
                 value={this.state.data.actual_address}/>
          <div className="invalid-feedback">{this.state.error.actual_address}</div>
        </div>

        <div className="form-group">
          <label htmlFor="legal_address">{I18n.t('key_AccountFormOrganizationInfo_LegalAddressLabel')}</label>
          <input className={`form-control form-control-sm ${this.state.error.legal_address ? 'is-invalid' : ''}`} type="text"
                 disabled={this.props.disabled}
                 id="legal_address" name="legal_address"
                 onChange={this.handleChange}
                 value={this.state.data.legal_address}/>
          <div className="invalid-feedback">{this.state.error.legal_address}</div>
        </div>

        <div className="form-group">
          <label htmlFor="ceo">{I18n.t('key_AccountFormOrganizationInfo_CEOLabel')}</label>
          <input className={`form-control form-control-sm ${this.state.error.ceo ? 'is-invalid' : ''}`} type="text"
                 disabled={this.props.disabled}
                 id="ceo" name="ceo"
                 onChange={this.handleChange}
                 value={this.state.data.ceo}/>
          <div className="invalid-feedback">{this.state.error.ceo}</div>
        </div>

        {
          (typeof this.props.submit === "function") && (
            <div className="form-group text-center">
              <Button type='submit'
                      className='btn btn-primary'
                      submit={this.handleSubmit}
                      disabled={this.handleDisabled()}
                      main={I18n.t('key_Update')}
              />
            </div>
          )
        }

      </div>
    )
  }
}

AccountFormOrganizationInfo.propTypes = {
  name: PropTypes.string,
  account: PropTypes.object,
  onChange: PropTypes.func,
  submit: PropTypes.func,
  cancel: PropTypes.func
};

export default AccountFormOrganizationInfo;

import React from "react";
import PropTypes from 'prop-types'
import {I18n} from "../utils/i18n";
import {connect} from "react-redux";
import moment from "moment";

const dateFormat = 'DD-MM-YYYY HH:mm:ss';

const TransactionCardList = ({items}) => {
  return (
    <table className="table table-hover table-bordered">
      <thead>
      <tr>
        <th scope="col" style={{
          textAlign: "center",
          maxWidth: "160px",
          width: "160px",
        }}>{I18n.t('key_TransactionCardList_OrderNumber')}</th>
        <th scope="col" style={{
          textAlign: "center",
          maxWidth: "120px",
          width: "120px",
        }}>{I18n.t('key_TransactionCardList_Date')}</th>
        <th scope="col" style={{
          textAlign: "center",
          minWidth: "200px"
        }}>{I18n.t('key_TransactionCardList_Description')}</th>
        <th scope="col" style={{
          textAlign: "center",
          maxWidth: "160px",
          width: "160px",
        }}>{I18n.t('key_TransactionCardList_Amount')}</th>
      </tr>
      </thead>
      <tbody>

      {
        items.map((item, index) => {
          return (
            <tr className="cursor-pointer" key={index}>
              <td className="align-middle" align="center" valign="middle">{item.order_number.toString().padStart(10, "0")}</td>
              <td className="align-middle" align="center" valign="middle">{moment(item.created).format(dateFormat)}</td>
              <td className="align-middle" align="left" valign="middle">{item.description}</td>
              <td align="center" valign="middle" className={`align-middle ${item.amount > 0 ? "text-success" : "text-danger"}`}>{item.amount.toFixed(2)}</td>
            </tr>
          )
        })
      }
      </tbody>
    </table>
  )
}

TransactionCardList.defaultProps = {
  items: [],
};

TransactionCardList.propTypes = {
  items: PropTypes.array,
};

const mapStateToProps = (state, props) => props;

export default connect(mapStateToProps)(TransactionCardList);

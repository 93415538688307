import React from "react";
import PropTypes from 'prop-types'
import {I18n} from "../utils/i18n";
import {Resource} from "../utils/helpers";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

class MediaFileCardList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleRemove = (e, item) => {
    e.stopPropagation();
    this.props.onRemove(item);
  };

  render() {
    const {files} = this.props;

    return (
      <div className="files-list__table">

        <table className="table table-hover">
          <thead>
          <tr>
            <th scope="col" style={{minWidth: "50px"}}>{I18n.t('key_MediaFileCardList_TableHeaderStatus')}</th>
            <th scope="col" style={{minWidth: "50px"}}>{I18n.t('key_MediaFileCardList_TableHeaderState')}</th>
            <th scope="col" style={{minWidth: "100%"}}>{I18n.t('key_MediaFileCardList_TableHeaderName')}</th>
            <th scope="col" style={{minWidth: "100%"}}>{I18n.t('key_MediaFileCardList_TableHeaderSize')}</th>
            <th scope="col" style={{width: "40px"}}>{I18n.t('key_MediaFileCardList_TableHeaderUpdated')}</th>
            <th scope="col" style={{width: "10px"}}/>
          </tr>
          </thead>
          <tbody>

          {
            files.sortDateDesc().map((item, index) => {
              return (
                <tr key={index}>
                  <td className="align-middle" align="center" valign="middle">
                    {(item.status.state === "error") &&
                    <span style={{width: "70px"}} className="badge badge-danger">{I18n.t('key_StatusMediaError')}</span>}
                    {(item.status.state === "ready") &&
                    <span style={{width: "70px"}} className="badge badge-success">{I18n.t('key_StatusMediaReady')}</span>}
                    {(item.status.state === "uploading") &&
                    <span style={{width: "70px"}} className="badge badge-primary">{I18n.t('key_StatusMediaUploading')}</span>}
                    {(item.status.state === "preparing") &&
                    <span style={{width: "70px"}} className="badge badge-primary">{I18n.t('key_StatusMediaPreparing')}</span>}
                    {(item.status.state === "deleting") &&
                    <span style={{width: "70px"}} className="badge badge-danger">{I18n.t('key_StatusMediaDeleting')}</span>}
                    {(item.status.state === "unknown") &&
                    <span style={{width: "70px"}} className="badge badge-warning">{I18n.t('key_StatusMediaUnknown')}</span>}
                  </td>
                  <td className="align-middle" align="center" valign="middle">
                    {(item.status.status === "created") &&
                    <span style={{width: "70px"}} className="badge badge-primary">{I18n.t('key_StatusMediaCreated')}</span>}
                    {(item.status.status === "accepted") &&
                    <span style={{width: "70px"}} className="badge badge-success">{I18n.t('key_StatusMediaAccepted')}</span>}
                    {(item.status.status === "rejected") &&
                    <span style={{width: "70px"}} className="badge badge-danger">{I18n.t('key_StatusMediaRejected')}</span>}
                  </td>
                  <td className="align-middle text-truncate"  style={{maxWidth: "300px"}}>{item.meta.name}</td>
                  <td className="align-middle" >
                    <span className="badge badge-info"
                          style={{width: "80px"}}>{Resource.MemoryBytesToHumanSize(item.meta.size, 2)}</span>
                  </td>
                  <td className="align-middle text-center">
                    {new Date(item.meta.created).toLocaleDateString("ru-RU", {
                      hour12: false,
                      hour: '2-digit',
                      minute: '2-digit'
                    })}
                  </td>
                  <td className="align-middle"  onClick={e => e.stopPropagation()}>
                    {
                      (item.status.state !== "deleting") && (
                        <div className="btn-group text-center cursor-pointer">
                          <div style={{width: "20px"}} data-toggle="dropdown">
                            <i className="fas fa-ellipsis-v cursor-pointer px-1"/>
                          </div>
                          <div className="dropdown-menu dropdown-menu-right">
                            <div className="dropdown-item text-danger cursor-pointer"
                                 onClick={e => this.handleRemove(e, item)}>
                              {I18n.t('key_Remove')}
                            </div>
                          </div>
                        </div>
                      )
                    }
                  </td>
                </tr>
              )
            })
          }

          </tbody>
        </table>

      </div>
    );
  }
}

MediaFileCardList.defaultProps = {
  files: {},
};

MediaFileCardList.propTypes = {
  files: PropTypes.object,
  onRemove: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => props;

export default withRouter(connect(mapStateToProps)(MediaFileCardList));
import {requestJSON} from "../utils/request";
import {serialize} from "../utils/helpers";

const api_host = window._env_.API_HOST;

class Media {

  static FileList(opts) {
    let uri = [api_host, "media", "file"].join("/");
    opts = opts || {};
    let qs = serialize({
      page: parseInt(opts.page, 10) || 1,
      limit: parseInt(opts.limit, 10) || 10,
    });
    uri += !!qs.length ? "?" + qs : "";
    return requestJSON("GET", uri, null, true);
  }

  static FileRemove(file) {
    let uri = [api_host, "media", "file", file].join("/");
    return requestJSON("DELETE", uri, null, true);
  }

}

export default Media